.product-image {
  float: left;
  width: 20%;
}
.product-details {
  float: left;
  width: 37%;
}
.product-price {
  float: left;
  width: 12%;
}
.product-quantity {
  float: left;
  width: 10%;
}
.product-removal {
  display: flex;
  width: 9%;
}
.product-line-price {
  text-align: left;
}
/* This is used as the traditional .clearfix class */
.group:before,
.shopping-cart:before,
.column-labels:before,
.product:before,
.totals-item:before,
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  content: "";
  display: table;
}
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  clear: both;
}
.group,
.shopping-cart,
.column-labels,
.product,
.totals-item {
  zoom: 1;
}
/* Apply clearfix in a few places */
/* Apply dollar signs */
.product .product-price:before,
.product .product-line-price:before,
.totals-value:before {
  content: "$";
}

.shopping-cart {
  margin-top: 30px;
  background-color: #f0f0f0;
  width: 60%;
  padding: 20px;
  border-radius: 5px;
}
/* Column headers */
.column-labels label {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.column-labels .product-image,
.column-labels .product-details,
.column-labels .product-removal {
  text-indent: -9999px;
}
/* Product entries */
.product {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.product .product-image {
  text-align: center;
}
.product .product-image img {
  width: 100px;
}
.product .product-details .product-title {
  margin-right: 20px;
}
.product .product-details .product-description {
  margin: 5px 20px 5px 0;
  line-height: 1.4em;
}
.product .product-quantity input {
  width: 40px;
}
.product .remove-product {
  border: 0;
  padding: 4px 8px;
  background-color: #c66;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}

.product .subscribe-product {
  border: 0;
  padding: 4px 8px;
  background-color: #ee7f0d;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 5px;
}

.product .remove-product:hover {
  background-color: #a44;
}
/* Totals section */
.totals .totals-item {
  float: right;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
}
.totals .totals-item label {
  float: left;
  clear: both;
  width: 79%;
  text-align: right;
}
.totals .totals-item .totals-value {
  float: right;
  width: 21%;
  text-align: right;
}
.totals .totals-item-total {
}
.checkout {
  float: right;
  border: 0;
  margin-top: 20px;
  padding: 6px 25px;
  background-color: #6b6;
  color: #fff;
  font-size: 25px;
  border-radius: 3px;
}
.checkout:hover {
  background-color: #494;
}
/* Make adjustments for tablet */
@media screen and (max-width: 650px) {
  .shopping-cart {
    margin: 0;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  .column-labels {
    display: none;
  }
  .product-image {
    float: right;
    width: auto;
  }
  .product-image img {
    margin: 0 0 10px 10px;
  }
  .product-details {
    float: none;
    margin-bottom: 10px;
    width: auto;
  }
  .product-price {
    clear: both;
    width: 70px;
  }
  .product-quantity {
    width: 100px;
  }
  .product-quantity input {
    margin-left: 20px;
  }
  .product-quantity:before {
    content: "x";
  }
  .product-removal {
    width: auto;
  }
  .product-line-price {
    /* float: right;
    width: 70px; */
  }
}
/* Make more adjustments for phone */
@media screen and (max-width: 350px) {
  .product-removal {
    float: right;
  }
  .product-line-price {
    /* float: right;
    clear: left;
    width: auto;
    margin-top: 10px; */
  }
  .product .product-line-price:before {
    content: "Item Total: $";
  }
  .totals .totals-item label {
    width: 60%;
  }
  .totals .totals-item .totals-value {
    width: 40%;
  }
}
/* 
.product-line-price {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
} */

.sub-type-cart {
  margin-top: 10px;
}

.inner-modal-wrap {
  width: 520px;
}

.cart-modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.13);
  background-color: white;
  margin: 0;
  padding: 0;
  padding: 5px 5px;
}
.cart-modal-header .btn {
  padding: 5px 11px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: none;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  margin-left: 10px;
}

.cart-modal-header .btn:focus {
  border: none !important;
}
.center-product-cartHeader {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-product-cartHeader span {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  font-size: 19px;
  line-height: 46px;
  text-shadow: rgba(255, 255, 255, 0.6) 0 1px 0;
  letter-spacing: -0.01em;
  white-space: nowrap;
}

.product-details-modal {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background-clip: padding-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: -9px;
}

.cart-productmodal .p-titl {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}
.p-img-se {
  display: flex;
  align-items: center;
}
.p-img-se img {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 9px 15px -5px rgba(0, 0, 0, 0.3);
  width: 90px;
  height: 70px;
}

.modal-about-p {
  margin-left: 8px;
}
.modal-about-p h1 {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.modal-about-p p {
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  color: #757575;
  font-size: 13px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0 !important;
}
.recommendedSub {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  top: -2px;
}
.recommendedSub p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
}
.button-subscribe-ed-modal button {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.527);
  border-color: transparent;

  font-size: 14px;
  width: 86px;
  border-radius: 5px;
  height: 35px;
}
.fyttt52h {
  font-size: 12px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.fyttt52h div button {
  font-size: 12px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: #ee7f0d !important;
}

@media (max-width: 600px) {
  .product-details-modal {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .recomdPubthgdt {
    display: none !important;
  }
  .recommendedSub {
    display: none !important;
  }
  .p-img-se img {
    width: 117px;
    height: 97px;
  }
  .button-subscribe-ed-modal {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .modal-about-p p {
    white-space: wrap !important;
  }
  .p-img-se {
    align-items: flex-start;
  }
}

@media (min-width: 600px) {
  .hdOnminSUBBTN {
    display: none !important;
  }
}
