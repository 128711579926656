.slide-wrap-home {
  /* background: rgb(238 127 13 / 34%); */
  height: 261px;
  width: 100%;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
  height: 261px !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: transparent !important;
}

.home-slide-wrap {
  display: flex;
  justify-content: space-around;
}

.home-slide-wrap .home-slide-text {
  width: 50%;
  margin-right: auto;
  padding-left: 30px;
}

.home-slide-text {
  text-align: left;
  padding-top: 40px;
}

.home-slide-text h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #002233;
}

.home-slide-text p {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4e4b66;
  margin-top: 17px;
  margin-bottom: 17px;
}

.home-slide-text button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
  color: white;
}

@media (max-width: 823px) {
  .home-slide-text h1 {
    padding-left: 23px;
  }
  .home-slide-text p {
    color: #4e4b66;
    margin-top: 4px;
    margin-bottom: 17px;
    margin-left: 27px;
  }

  .home-slide-text button {
    margin-left: 26px;
  }

  .slide-wrap-home {
    height: 420px;
  }
}
@media (max-width: 620px) {
  .hideSlideOn-md-screen {
    display: none !important;
  }
}

@media (min-width: 620px) {
  .mobile-slide {
    display: none !important;
  }

  .mobile-slide-hideMobile {
    display: none;
  }
}
@media (max-width: 620px) {
  .slide-wrap-home {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  slider-wrapper {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .mobile-slide {
    background-image: url(../../assets/home-slide.png);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.85),
        rgba(255, 252, 245, 0.85)
      ),
      url(../../assets/home-slide.png) no-repeat top center;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-position: center;
  }

  .mobile-slide h1 {
    font-size: 22px;
    /* color: white; */
  }

  .mobile-slide p {
    font-size: 17px;
    margin-top: 8px;
  }
}

.home-slide-mobileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-slide-mobileBtn button {
  width: 120px;
  height: 40px;
  background-color: #ee7f0d;
  color: white;
  border-radius: 3px;
}

.adsAndNewCardWrap {
  display: flex;
  width: 100%;
  padding-right: 30px;
}

.adsNewSwrap {
  width: 75%;
}

.home-ads {
  width: 25%;
  height: 700px;
  background-color: white;
  margin-top: 50px;
  border: 1px solid #f3d5b3;
  border-radius: 5px;
  padding: 15px 10px 12px 10px;
}

.homePageHeaderAds {
  text-align: center;
  font-size: 22px;
}

.adsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.adsContainer img {
  width: 90%;
  margin-bottom: 10px;
  height: 200px;
  border-radius: 3px;
  cursor: pointer;
}
.waletBalanceModalWrap {
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.waletBalanceModalWrap .kyudry {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: #002233;
  margin-right: 15px;
}

.waletBalanceModalWrap .jhddsr {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  color: #f3a658;
  font-weight: 600;
  font-size: 15px;
}
.loadingSvgHomeWrap {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.svgHomeLoader {
  width: 280px;
  height: 400px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

@media (max-width: 425px) {
  .svgHomeLoader {
    width: 100%;
    margin-right: 0 !important;
  }
}
