:root {
  --textViewPdfFont: pdfFont;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
html {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styleTable {
  border-radius: 5px;
}

.styleTable thead tr th {
  background-color: transparent !important;
  color: #334d6e !important;
  font-weight: 600;
}

.styleTable tbody tr .MuiTableCell-root {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

button:focus {
  border: none;
  outline: none;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.react-responsive-modal-modal {
  max-width: none !important;
  border-radius: 4px;
  /* display: flex; */
}
.section-wrap {
  padding: 6px 30px 60px 20px;
}

.new-section {
  margin: 40px 10px 30px 30px;
}

@media (max-width: 500px) {
  .new-section {
    margin: 10px 10px 30px 10px;
  }
}

.AppError {
  color: rgba(247, 36, 36, 0.842);
  font-size: 12px;
  display: block;
  font-style: italic;
}

select,
input,
button {
  outline: none !important;
  border: none;
}

select,
input {
  padding-left: 10px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #ee7f0d;
    --active-inner: white;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #ee7f0d;
    --border-hover: #ee7f0d;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 21px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}

input[type="radio"] {
  width: 15px !important;
  height: 15px !important;
}

/* input[type="checkbox"] {
  width: 20px !important;
  height: 15px !important;
} */

input[type="radio"]:after {
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  background: var(--active-inner);
  opacity: 0;
  transform: scale(var(--s, 0.7));
}

@font-face {
  font-family: generalFont;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: boldFont;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

* {
  font-family: generalFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: boldFont;
}

.hr-global {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.06);
}

.app-spinner:first-child div {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 50px;
}

.app-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-modal-wrap {
  background-color: #f2f2f2;
  padding: 0px 0 40px 0;
  padding-bottom: 23px;
}

.react-responsive-modal-modal {
  background: none !important;
  padding: 0 !important;
}

.addSubAccountBtn {
  width: 100%;
  background: white !important;
}

.addSubAccountBtn button {
  background: #ee7f0d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 16px;
  font-size: 13px;
  color: white;
}
.mbbfed9hyt {
  background: white !important;
  padding: 20px;
  letter-spacing: 0.75px;
  font-size: 13px !important;

  color: #00000080;
}
.xwijkds {
  color: black;
  font-weight: 600;
  padding-top: 29px;
  text-align: center;
  font-size: 20px;
}

.pay-now-button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 40px;
  max-width: 168px;
  width: 100%;
  color: white;
  font-weight: 600;
}

.fluterWvePayBTN {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.fluterWvePayBTN div button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  height: 60px;
  width: 168px;
  color: white;
}
.app-wrap-container {
  padding: 20px 20px 20px 20px;
}

.avatarProfileImageCrop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background-color: grey;
}

.avatarProfileImageCrop label {
  color: white !important;
}

.avatarProfileImageCrop div div input {
  border: 1px solid red !important;
}

.card {
  border: none !important;
  background-color: inherit !important;
  margin: 0;
  padding: 0;
}

.card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.datanotFound-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.datanotFound-wrap img {
  width: 80%;
  margin-top: 30px;
  border-radius: 5px;
}

.datanotFound-wrap p {
  text-align: center;
  margin-top: 20px;
  font-size: 19px;
  font-weight: 600;
}
.innerModalWrapWallet {
  /* width: fit-content !important; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white !important;
}

.css-2b097c-container {
  width: 90% !important;
  height: 45px !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}

.css-yk16xz-control {
  height: 100% !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}

.css-1okebmr-indicatorSeparator {
  height: 30px !important;
}

.css-tj5bde-Svg {
  position: relative !important;
  bottom: 5px !important;
}

.css-1wa3eu0-placeholder {
  position: absolute !important;
  top: 40% !important;
}

.errorNotFoundImg {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.errorNotFoundText {
  width: 400px;
}

.errorNotFoundTextImg img {
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorNotFoundText p {
  font-size: 14px;
  font-weight: 600;
}

.errorNotFoundText span {
  font-size: 14px;
  font-weight: 400;
}
/* #holdon-overlay {
  background-color: #1847B1 !important;
} */
.sk-dot1,
.sk-dot2 {
  background-color: #ee7f0d !important;
}

.sk-dot2,
.sk-dot2 {
  background-color: #ee7f0d !important;
}
a {
  color: black !important;
}
/* .rpv-toolbar-right :nth-child(3n + 2) {
  display: none !important;
} */

/* .rpv-default-layout-sidebar-header {
  display: none !important;
} */
.rpv-open-button {
  display: none !important;
}

.rpv-toolbar-right .rpv-toolbar-item:nth-child(3n + 3) {
  display: none !important;
}

.rpv-toolbar-right .rpv-toolbar-item:nth-child(3n + 4) {
  display: none !important;
}

.rpv-default-layout-body {
  top: 50px !important;
}

.rpv-default-layout-sidebar-headers
  .rpv-default-layout-sidebar-header:nth-of-type(2) {
  display: none;
}

.rpv-default-layout-sidebar-headers
  .rpv-default-layout-sidebar-header:nth-of-type(3) {
  display: none;
}

@media (max-width: 500px) {
  .rpv-toolbar-left .rpv-toolbar-item:nth-child(3n + 3) {
    display: none !important;
  }
}
/* .rpv-toolbar-right {
} */
/* .rpv-open-button {
  display: none !important;
} */

.rpv-default-layout-toolbar {
  position: fixed;
  width: 100vw;
  top: 75px;
  z-index: 12;
}

.rpv-core-progress-bar-inner {
  background-color: #ee7f0d !important;
}

.rpv-default-layout-sidebar-tabs {
}

/* .rpv-toolbar-item {
  display: none !important;
} */

@media (max-width: 575px) {
  .inner-modal-wrap {
    width: 90vw !important;
  }
}

/* .rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 350px !important;
} */

/* @media (max-width: 700px) {
  .rpv-thumbnail-list {
    width: 100px !important;
  }

  .rpv-default-layout-sidebar-headers {
    display: none !important;
  }
} */

/* .rpv-core-page-layer,
.rpv-core-page-size-calculator {
  width: 100vw !important;
}
.rpv-core-canvas-layer {
  width: 100vw !important;
}
.rpv-core-canvas-layer canvas {
  width: 100vw !important;
} */

.fullPageErro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.fullPageErro img {
  width: 500px;
  height: 300px;
}

.fullPageErro p {
  margin-top: 20px;
  font-size: 19px;
}

.inner-modal-wrap-mLogin {
  width: 400px;
}

.inner-modal-wrap-mLogin .inPutHomeWrapP {
  width: 100%;
}

.inner-modal-wrap-mLogin .login-wrap-home-input {
  min-width: 100%;
}

.inner-modal-wrap-mLogin .submit-login-details-home button {
  width: 100%;
}

.login-modal-header-text {
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 23px;
}

.logoutBtnLoginModal {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logoutBtnLoginModal span {
  color: rgba(255, 0, 0, 0.76);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.wrapTransationTable {
  width: 90%;
  border: 1px solid rgba(128, 128, 128, 0.507);
  border-radius: 5px;
  margin-top: 20px;
}
table thead tr th {
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

table tbody tr:hover {
  background-color: rgba(128, 128, 128, 0.13) !important;
  cursor: pointer !important;
}

table tbody tr {
  color: #000000 !important;
  font-size: 12px !important;
  white-space: nowrap;
}

@media (max-width: 437px) {
  .inner-modal-wrap-mLogin {
    width: 90vw;
  }
}

.termsOfUsePdfPage {
  position: relative;
  width: 100vw;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.termsOfUsePdfPage-inner {
  position: fixed;
  z-index: 20;
  left: 50%;
  width: 27px;
  height: 27px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: red;
  /* -webkit-animation: bounce 5s infinite alternate; */
  /* animation: bounce 0.17s infinite alternate; */
  cursor: pointer;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1.7s;
  animation-duration: 1.7s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  display: none;
}

.termsOfUsePdfPage-inner img {
  width: 90%;
  height: 90%;
}

.swal-button--confirm {
  background-color: #ee7f0d !important;
  border: none !important;
  outline: none !important;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .termsOfUsePdfPage-inner {
    width: 35px;
    height: 35px;
  }
}

@font-face {
  font-family: pdfFont;
  src: url(./assets/fonts/pdfFont/Merriweather-Bold.ttf);
}

.addSubAccountHeader {
  font-size: 15px !important;
  padding: 20px 20px 0px 20px;
  color: #6e7191;
}

.cancelSub {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.cancelSub svg {
  width: 400px;
  height: 400px;
}

.cancelSub p {
  width: 400px;
  text-align: center;
}

.cancelSub button {
  background-color: #ee7f0d;
  border: none;
  padding: 10px;
  width: 150px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
}
