.fund-ur-wallet-type {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  border-radius: 5px;
}

.fundWalletMethodLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fundWalletMethods {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.activeRouteFund {
  text-align: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.activeRouteFundAfter::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ee7f0d;
  margin-top: 6px;
  text-align: center;
}

.walletTransfeerDetails {
  text-align: center;
  margin-top: 10px;
  padding: 10px 0px 40px 0px;
}

.fundWalletHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 90%;
}

.fundWalletHeader button {
  background-color: rgba(231, 21, 21, 0.788);
  color: white;
  height: 10px;
  height: 34px;
  width: 93px;
  border-radius: 4px;
}

.fundWalletHeader p {
  font-size: 20px;
}
.tranferDoneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tranferDoneBtn button {
  background-color: #ee7f0d;
  color: white;
  height: 10px;
  height: 34px;
  width: 98px;
  border-radius: 4px;
  font-size: 13px;
}

.fundUssdBankDetails {
  margin-top: 10px;
}

.fundUssdBankDetails select {
  height: 45px;
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.1);
  width: 80%;
}

.walletBalanceDetailsuyf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 10px;
}

.fundWalletMethodsHeader {
  margin-top: 14px;
  text-align: center;
  font-size: 16px;
  color: #002233;
  font-weight: 500;
}

.walletActionsButton {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.walletActionsButton button img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.walletActionsButton button {
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.walletActionsButton button:nth-child(3n + 1) {
  background-color: #ee7f0d;

  border-radius: 3px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
}

.walletActionsButton button:nth-child(3n + 2) {
  background-color: #002333;

  border-radius: 3px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
}

.walletActionsButton button:nth-child(3n + 3) {
  background-color: #eff0f7;
  border-radius: 3px;
  color: #002333;
  margin-right: 5px;
  font-size: 12px;
}

.fundvjgahdh {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  color: #002233;
  padding: 5px 30px 10px 30px;
}

.xModalT {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin-top: 10px;
}
.xModalT i {
  cursor: pointer;
}

.userBankDrtailswrap {
  padding: 10px;
  background: rgba(44, 44, 44, 0.1);
}
.uTrD p {
  padding: 0;
  margin: 0;
  font-size: 17px;
  color: #002233;
}
.uTrD p:nth-child(3n + 2) {
  color: #ee7f0d;
  margin-top: 5px;
  margin-bottom: 5px;
}

.inner-modal-wrap-tr {
  width: 420px;
  background-color: white !important;
}

.walletBalanceDetailsuyf p {
  margin-right: 15px;
}

.fundWalletTInput input {
  border-bottom: 1px solid rgba(102, 102, 102, 0.45);
  font-size: 14px;
  text-align: center;
  color: grey;
}
.walletTransfeerDetailsCard {
  padding-bottom: 0;
}

.iq2hk {
  margin: 0;
  padding: 0;
  margin-top: 15px;
}

.walletActionsButtonCard button {
  margin-bottom: 0;
}

.walletActionsButtonCard button:nth-child(3n + 3) {
  margin-top: 15px;
}
.walletActionsButtonCard {
  padding-bottom: 20px;
}

@media (max-width: 485px) {
  .inner-modal-wrap-tr {
    width: 90vw;
  }

  .walletActionsButton button:nth-child(3n + 3) {
    margin-top: 10px;
  }
}

.freeReadModalImWrap{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.freeReadModalImWrap img{
  width: 90%;
}