.navbar-wrap {
  background-color: white;
  height: 80px;
  position: fixed;
  width: 100vw;
  top: 74px;
  z-index: 40;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.103));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.103));
}

.navbar-position {
  position: relative;
}
.navbar-pad {
  padding: 10px 20px 10px 0;
}

.sidebar-wrap {
  height: calc(100vh - -6px);
  bottom: 0;
  background: rgba(238, 127, 13, 0.14);
  width: 240px;
  position: fixed;
  z-index: 55;
}

.backgroundImage-wrap {
  background-image: url(../../assets/body.jpeg);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  /* background-color: #f8f8f8; */
  background-position: center;
  position: fixed;
  z-index: 0;
  top: 0;
  background-color: #e8e9eb;
}

.app-container {
  position: relative;
  left: 220px;
  width: calc(100vw - 275px);
  top: -7px;
  padding-bottom: 30px;
  padding: 0;
}

.sidebar-list {
  list-style-type: none;
  padding-left: 10px;
  padding-top: 19px;
}

.sidebar-list li {
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 9px;
}

.sidebar-list li .inner-navlist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sidebar-list li .inner-navlist div:first-child img {
  width: 20px;
}

.sidebar-list li .inner-navlist div:first-child .inner-navHeader {
  font-weight: 600;
  font-size: 14px;
  color: #002233;
  margin-left: 5px;
}

.sidebar-radio {
  width: 20px !important;
  height: 20px !important;
}

.sidebar-radio[type="radio"]:after {
  width: 20px !important;
  height: 20px !important;
  /* margin-bottom: 4px; */
  top: -1px !important;
  left: -1px !important;
}

.sidebar-datepicker input {
  border: 1px solid rgba(128, 128, 128, 0.76);
  padding: 10px;
  border-radius: 3px;
}

.sidebar-list li .inner-navlist div:last-child i {
  font-size: 15px;
}

.innerList-drop-down {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.innerList-drop-down span {
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1.3px;
}

.innerList-drop-down span input {
  margin-right: 10px;
}

.sidebar-hr {
  height: 0px;
  position: relative;
  left: -11px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.029);
}

.active-dot-sidebar {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(238, 127, 13);
}

.sidebar-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sidebar-chf5 {
  display: flex;
}

.sidebar-i {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.active-sidebar-route {
  background-color: white;
  border-right: 1px solid rgb(238, 127, 13);
}

.sidbar-link-total {
  font-weight: 600;
}

.sidbar-link-total span {
  font-weight: 400;
  font-size: 13px;
}

.sidebarLogo {
  margin-top: -18px;
  padding-top: 0;
  margin-bottom: 20px;
}

.sidebarLogo img {
  width: 110px;
  margin-top: 20px;
}

.navbar-pad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-logo-wrap-layout-page {
  margin-right: auto;
  width: 65%;
}

.app-logo-wrap-layout-page img {
  width: 129px;
  /* height: 40px; */
  margin-right: 29px;
}

.avatarIcaon-nav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nav-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-actions ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.nav-actions ul li {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-left: 10px;
}

.nav-actions ul li:hover {
  color: orange;
}
.nav_search_app button {
  background-color: #ee7f0d;
  color: white;
  width: 50px;
  border: none;
  height: 40px;
  border-radius: 0px 5px 5px 0;
}

.nav_search_app {
  width: 100%;
  background-color: red;
  flex: 1;
  margin-left: 20px;
}

.nav_search_app input {
  color: black;
  border: 1px solid #e4e4e4;
  height: 40px;
  padding-left: 10px;
  width: 65%;
}

.nav_search_app select {
  color: black;
  border: 1px solid #e4e4e4;
  height: 40px;
  border-radius: 5px 0px 0px 5px;
  background: #e1e1e1;
}

.nav-actions ul li,
a {
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 24px;
  color: #002333;
}

.nav-user-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nav-user-icon span {
  margin-right: 10px;
}

.nav_wallet_li span {
  line-height: 14px;
  margin-top: 8px;
  margin-left: 5px;
}

.nav_wallet_li i {
  font-size: 18px;
  color: #002333 !important;
}

.harmburger_menu-nav i {
  color: #002333 !important;
  position: relative;
  right: 28px;
  font-size: 25px;
  top: 8px;
  cursor: pointer;
}

.responsiveSideBar {
  height: 100vh;
  background-color: transparent;
  width: 200px;
  position: fixed;
  z-index: 55;
}

/* @media (min-width: 906px) { */
.sidebar-wrap {
  background-color: white;
  -webkit-filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
  filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
}

.navbar-wrap {
  background-color: white;
  -webkit-filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
  filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
}

.app-container {
  position: relative;
  left: 0px;
  width: 100vw;
  top: 74px;
  overflow-x: hidden;
}

.navbar-wrap {
  height: 75px;
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  padding-left: 35px;
  z-index: 40;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.active-sidebar-route {
  background-color: #0a1531;
  color: white;
}
.navbar-pad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.backgroundImage-wrap {
  display: none;
}

#App .navRoutes {
  margin-right: auto;
  padding-left: 100px;
}

#App .navRoutesActions {
  padding-right: 20px;
}

#AppWrap .generalPadding {
  padding-left: 20px;
  padding-top: 40px;
}

.ham-toggle {
  display: block;
}

#App {
  justify-content: left;
}

#App .navRoutes {
  display: none;
}

#App .navRoutesActions {
  margin-left: auto;
}

#AppWrap {
  width: 100vw;
  background: #e5e5e5;
  height: calc(100vh - 75px);
}

#AppWrap .generalPadding {
  padding-left: 20px;
  padding-top: 40px;
}

.responsiveSideBar {
  width: 0;
  z-index: 8;
}

.responsiveSideBarOpen {
  -webkit-animation: showbar 500ms ease-in forwards;
  animation: showbar 500ms ease-in forwards;
}

.AppCloseSidebar {
  -webkit-animation: hidebar 500ms ease-out forwards;
  animation: hidebar 500ms ease-out forwards;
}

@-webkit-keyframes showbar {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes showbar {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes hidebar {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-271px);
  }
}

@keyframes hidebar {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-271px);
  }
}
.initSidebarState {
  transform: translateX(-271px);
}
#ApiHeader {
  width: 95%;
}

.navRoutesLogo {
  margin-left: 0 !important;
}
/* } */

.ApisideDropdown {
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
  margin-top: 10px;
  margin-top: 30px;
  margin-left: 0;
}

.ApisideDropdown .sidebarHeader-list {
  font-family: Gotham;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border: 0.277929px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  border-left: none;
  border-right: none;
}

.subSidbar-Details {
  margin-top: 10px;
  list-style-type: none;
}

.subSidbar-Details .subSidbar-Details-child {
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  border: none;
  color: white;
  cursor: pointer;
}

.ham-toggle {
  display: none;
}

@media (max-width: 611px) {
}

@media (max-width: 325px) {
  #App .navRoutesActions button:last-child {
    width: 60px;
    margin-left: 10px;
  }

  #App .navRoutesActions button:first-child {
    width: 60px;
  }

  #App .navRoutesLogo img {
    width: 70px;
    margin-left: 13px;
  }

  #AppWrap .generalPadding {
    padding-top: 10px;
  }
}

html,
body {
  height: 100%;
  min-height: 100%;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.page-wrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper button {
  padding: 20px;
  border: none;
  background: #d5d8e7;
  position: relative;
  outline: none;
  border-radius: 5px;
  color: #292d48;
  font-size: 18px;
}
.page-wrapper button .cart-item {
  position: absolute;
  height: 24px;
  width: 24px;
  top: -10px;
  right: -10px;
}
.page-wrapper button .cart-item:before {
  content: "1";
  display: block;
  line-height: 24px;
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-weight: 600;
  background: #2bd156;
  color: white;
  border-radius: 20px;
  text-align: center;
}
.page-wrapper button.sendtocart .cart-item {
  display: block;
  -webkit-animation: xAxis 1s forwards cubic-bezier(1, 0.44, 0.84, 0.165);
  animation: xAxis 1s forwards cubic-bezier(1, 0.44, 0.84, 0.165);
}
.page-wrapper button.sendtocart .cart-item:before {
  -webkit-animation: yAxis 1s alternate forwards
    cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}
.cart {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: #292d48;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.cart i {
  font-size: 25px;
  color: white;
}
.cart:before {
  content: attr(data-totalitems);
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -12px;
  right: -12px;
  background: #2bd156;
  line-height: 24px;
  padding: 0 5px;
  height: 24px;
  min-width: 24px;
  color: white;
  text-align: center;
  border-radius: 24px;
}
.cart.shake {
  -webkit-animation: shakeCart 0.4s ease-in-out forwards;
  animation: shakeCart 0.4s ease-in-out forwards;
}
@-webkit-keyframes xAxis {
  100% {
    transform: translateX(calc(50vw - 105px));
  }
}
@keyframes xAxis {
  100% {
    transform: translateX(calc(50vw - 105px));
  }
}
@-webkit-keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh + 75px));
  }
}
@keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh + 75px));
  }
}
@-webkit-keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.navbar-login-access a {
  text-decoration: none;
  font-size: 13px !important;
  font-weight: 600;
}

.navbar-login-access a:hover {
  color: orange;
}

#app-router-home {
  position: relative;
  top: 73px;
  margin-left: 0;
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding-left: 25px;
  height: 75px;
  overflow: hidden;
  overflow-x: scroll;
}

#app-router-home .app-router-homeharmburger {
  width: 10%;
  color: #002233;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 50px;
  align-items: center;
}

#app-router-home .app-router-homeharmburger i {
  margin-right: 7px;
  color: #002233;
}

#app-router-home .center-routers {
  display: flex;
  justify-content: center;
  width: 80%;
}

#app-router-home .center-routers span {
  margin-right: 15px;
  cursor: pointer;
  font-size: 14px;
}

#app-router-home .center-routers .activeNews {
  font-weight: 600;
  color: #14142b;
  display: flex;
}

#app-router-home .center-routers .inactiveNews {
  color: #6e7191;
  display: flex;
}

.sidebar-header {
  color: black;
  display: flex;
  justify-content: space-between;
}

.sidebar-header img {
  margin-right: 8px;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.sidebar-header span {
  font-weight: 600;
  font-size: 14px;
  color: #002233;
}

.profile-dropdown-nav {
  position: fixed;
  right: 51px;
  top: 62px;
  z-index: 50;
  width: 400px;
  height: 200px;
  width: 309px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.nav-user-drop67hn {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-top: 30px;
}

.nav-user-drop67hn img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.nav-user-drop67hn span {
  display: flex;
  flex-direction: column;
}

.account-ausername,
.account-auseremail {
  color: #6e7191;
  font-size: 10px;
}

.account-btn-acc {
  background: #ee7f0d;
  border-radius: 40px;
  width: 88px;
  height: 33px;
  color: white;
  font-size: 11px;
  margin-top: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.navbar-dropdownlinks {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.navbar-dropdownlinks span {
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #444444;
  cursor: pointer;
}

.navbar-dropdownlinks span img {
  /* width: 20px; */
  margin-right: 8px !important;
  /* height: 20px; */
}
.gjfu6ghs2d {
  color: black;
  font-weight: 600;
  margin-top: 5px;
}

.sidebar-quick-access {
  background-color: #ee7f0d !important;
  position: relative;
  left: -10px;
  width: 105%;
  border-radius: 0 !important;
  color: white !important;
}

.sidebar-quick-access .inner-navHeader {
  margin-left: 25px !important;
  color: white !important;
}

.sidebar-quick-access-link {
  background-color: #ebeff2 !important;
  position: relative;
  left: -10px;
  width: 105%;
  border-radius: 0 !important;
  color: white !important;
}

.sidebar-quick-access-link .inner-navHeader {
  margin-left: 25px !important;
  color: #002233 !important;
}

.show-nav-border {
  border-right: 1px dashed rgba(0, 0, 0, 0.25);
  padding-right: 16px;
}

.selectCategoriesWrapHome {
  border: 1px solid rgba(128, 128, 128, 0.219);
  padding: 5px;
  border-radius: 4px;
}

@media (max-width: 1354px) {
  .hidenavbrsearch {
    display: none;
  }
}

@media (max-width: 554px) {
  .hidemycartAndWalllet {
    display: none !important;
  }
  .navbar-pad {
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-wrap {
    padding-left: 5px;
    padding-right: 0;
  }
  .remjegdwy {
    margin-right: 0 !important;
  }
  .nav-actions ul li,
  a {
    margin-right: 0 !important;
  }
  .nav-user-icon span {
    margin-right: 5px !important;
  }
}

@media (min-width: 554px) {
  .hideOnDesk {
    display: none !important;
  }
}

@media (max-width: 581px) {
  .profile-dropdown-nav {
    right: 0;
  }
  #app-router-home .center-routers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
}

@media (max-width: 459px) {
  .hideFilterText {
    display: none !important;
  }
  #app-router-home .center-routers .inactiveNews,
  .activeNews {
    font-size: 12px !important;
  }
}

.hjsgt i {
  font-weight: 600;
  color: black;
  font-size: 20px;
  margin-right: 7px;
}

.hjsgt img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

@media (max-width: 693px) {
  .hdPoNmobile {
    display: none !important;
  }
}

.searchMainDrop {
  position: absolute;
  left: 200px;
  width: 380px;
  height: 500px;
  background-color: white;
  z-index: 6;
  top: 85px;
  -webkit-filter: drop-shadow(20px 8px 20px rgba(128, 128, 128, 0.521));
  filter: drop-shadow(20px 8px 20px rgba(128, 128, 128, 0.521));
  border-radius: 5px;
  padding: 20px 12px 12px 12px;
  overflow: hidden;
  overflow-y: scroll;
}

.searchMainDrop::-webkit-scrollbar {
  opacity: 0;
}

.searchMainDrop ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchMainDrop ul li {
  margin-bottom: 10px;
  padding: 15px;
  width: 99%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: 200ms ease forwards;
}

.searchMainDrop ul li:hover {
  transform: translateY(-5px);
  border-bottom: 1px solid #ee7e0d70;
}
.searchMainDrop ul li:nth-child(odd) {
  background-color: rgba(128, 128, 128, 0.123);
}
