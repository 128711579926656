.redeem-card-container {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.redeem-card-container .left-col {
}

.redeem-card-container .right-col {
  padding: 24px;
}

.redeem-card-container .left-col .heading {
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}

.redeem-card-container .left-col .heading2 {
  font-size: 40px;
  font-weight: 700;
  color: #ee7f0e;
  margin-top: 30px;
}

.redeem-card-container .left-col .benefit {
  display: flex;
  align-items: start;
  gap: 20px;
  margin-bottom: 20px;
}

.redeem-card-container .left-col .benefit p {
  font-size: 25px;
  font-weight: 500;
}

.redeem-card-container .checkbox-icon {
  width: 50px;
}

.redeem-card-container .right-col .redeem-box {
  width: 100%;
  min-height: 300px;
  padding: 20px;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.redeem-card-container .right-col .redeem-box .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.redeem-card-container .right-col .redeem-box .redeem-coupon-image {
  width: 100%;
}

.redeem-card-container .right-col .redeem-box .benefit2-container {
  margin-top: 20px;
}

.redeem-card-container .right-col .redeem-box .benefit2-container .benefit2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}

.redeem-card-container .right-col .redeem-box .benefit2-container .benefit2 p {
  font-size: 20px;
  align-self: center;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.redeem-card-container
  .right-col
  .redeem-box
  .benefit2-container
  .benefit2
  .icon {
  width: 40px;
  height: 40px;
  border: 2px solid #ee7f0e;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redeem-card-container .right-col .redeem-box .code-input {
  height: 55px;
  border-radius: 8px;
  border: 1px;
  background: #eff0f6;
  width: 100%;
  border: 1px solid #bdbdbd70;
  padding: 20px;
}

.redeem-card-container .right-col .redeem-box .message {
  font-size: 12px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
}
.redeem-card-container .right-col .redeem-box button {
  background: #ee7f0e;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 700px) {
  .redeem-card-container {
    /* padding: 24px; */
    grid-template-columns: repeat(1, 1fr);
  }

  .redeem-card-container .left-col .heading {
    /* text-align: center; */
    font-size: 25px;
    line-height: 45px;
  }

  .redeem-card-container .left-col .heading2 {
    /* text-align: center; */
    font-size: 30px;
  }

  .redeem-card-container .checkbox-icon {
    width: 30px;
  }

  .redeem-card-container .left-col .benefit p {
    font-size: 18px;
    font-weight: 500;
  }

  .redeem-card-container .left-col .benefit {
    gap: 10px;
  }

  .redeem-card-container .right-col {
    padding: 12px;
  }
  .redeem-card-container .right-col .redeem-box {
  }
}
