.news-wrap-home {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 7px;
}

.show-new-home {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
}

.show-new-home-as-grid {
  --auto-grid-min-size: 200px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  width: 100%;

  grid-gap: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 2px;
  opacity: 0;
  visibility: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
  opacity: 0;
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ee7f0d;
  border-radius: 1px;
  opacity: 0;
  visibility: hidden;
}

.newsCard {
  position: relative;
  width: 219px;
  height: 255px;
  /* margin: 5rem auto; */
  background-color: #fff;
  color: #fff;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: -7px;
  overflow: hidden;
}
.news-Slide-up {
  margin-right: 20px;
}

.newsCard .magazin-img {
  width: 232px;
  height: 100%;
  left: -6px;
  position: relative;
}

.like-icon-wrap {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  cursor: pointer;
}

.add-to-cart-btn {
  position: absolute;
  width: 218px;
  height: 100%;
  top: -41px;
  left: 1px;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
}

.add-to-cart-btn:hover {
  background: rgba(0, 0, 0, 0.521);
}

.add-to-cart-btn button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  width: 110px;
  height: 42px;
  border-radius: 27px;
  font-size: 14px;
  background: #ee7f0d;
  color: white;
  border: none;
  outline: none;
}

.add-to-cart-btn:hover button {
  opacity: 1;
  transform: translateX(-20px);
  transition: transform 0.4s, opacity 0.1s;
}

.publicationSubscribed {
  position: relative;
}

.publicationSubscribed button {
  position: absolute;
  background: #ee7f0c;
  right: 15px;
  bottom: 28px;
  width: 80px;
  font-size: 13px;
  height: 35px;
  color: white;
  border-radius: 4px;
  transition: all ease 500ms;
}

.publicationSubscribed button:hover {
  transition: all ease 500ms;
  transform: translateY(-7px);
}

.news-date-sec {
  position: absolute;
  bottom: -40px;
  width: 100%;
  background: #ee7f0c;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 0 0px 5px 5px;
}

.magazine-title-home p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}

.magazine-comment-home {
  white-space: nowrap;
}

.magazine-title-home p:last-child {
  font-size: 9px;
  color: white;
  white-space: nowrap;
}

.magazine-comment-home span {
  margin-left: 2px;
  margin-right: 6px;
  font-size: 12px;
}

.magazine-comment-home img {
  cursor: pointer;
}

.edition-type-home {
  font-size: 25px;
  margin-left: 5px;
  font-weight: 600;
  font-size: 24px;
  color: #002233;
  margin-top: 10px;
  margin-bottom: 23px;
}

.single-book-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.sigle-book-col-one {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 440px;
  margin-top: 40px;
  background: #f3f3f3;
  padding-bottom: 10px;
  border-radius: 5px;
}

.sigle-book-col-one .magazin-img-single {
  margin: 10px 5px 10px 7px;
}

.single-book-name {
  color: white;
  font-size: 16px;
  margin-left: 10px;
  width: 245px;
  font-size: 12px;
}

.sigle-book-col-one .single-book-sub-btn {
  font-size: 12px;
  background-color: orange;
  padding: 10px;
  border: none;
  margin-left: 10px;
  border-radius: 4px;
  color: white;
  width: 84px;
  height: 37px;
}

.sigle-book-col-two {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 72%;
  overflow: hidden;
  margin-right: 27px;
  /* background-color: orange; */
}

.wraopNewpaperIMg {
  background-color: #f3f3f3;
  width: 280px;
  height: 368px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 15px 15px 0px 0;
  padding: 5px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.paperImgNew {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  margin-top: 10px;
}

.paperImgNew img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 5px;
}

.view_paper_card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
}

.TNSNewsPaperCover {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}

.paperImgHover {
  width: 280px;
  height: 364px;
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 15px 15px 0px 0;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
}

.paperImgHover:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

.newspaperWrapBody {
  width: 280px;
  height: 413px;
  margin-right: 10px;
  margin-bottom: 13px;
  justify-self: center;
  overflow: hidden;
}

.free-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ee7f0c;
  width: 90px;
  height: 40px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 4px;
  border-bottom-left-radius: 15px;
  z-index: 10;
}

.inactive-button {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e02909;
  width: 120px;
  height: 40px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  border-bottom-right-radius: 15px;
  z-index: 10;
}

.TNSAboutMagazine {
  background-color: #f3f3f3;
  width: 100%;
  padding-left: 12px;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.subscribeToPublicationBtn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.subscribeToPublicationBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.followEditionIcon {
  margin-right: 10px;
  margin-top: 5px;
}

.followEditionIcon i {
  color: white;
  position: relative;
  cursor: pointer;
  font-size: 23px;
  margin-top: 16px;
  margin-left: 14px;
}
.subscribeToPublicationBtn button {
  background-color: #ee7f0c;
  width: 120px;
  height: 40px;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 14px;
  margin-right: 13px;
}

.TNSAboutMagazine-name {
  display: flex;
  flex-direction: column;
}

.TNSAboutMagazine-name p {
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.856);
}
.TNSAboutMagazine-name p:first-child {
  text-transform: uppercase;
}

.TNSAboutMagazine-socialEngagement {
  color: black;
}

.TNSAboutMagazine-socialEngagement span {
  margin-right: 10px;
}

.TNSAboutMagazine-socialEngagement i {
  margin-right: 4px;
  color: #ee7f0c;
}

.linehr-publlicPub {
  width: 100%;
  height: 10px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

.publicationPublicAbout {
  display: flex;
}

.shraePublication-shareSocials {
  display: flex;
}

.shraePublication-shareSocials div {
  margin-right: 8px;
}
.pubgiu-img-wrap {
  height: 90px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.pubgiu-img-wrap img {
  height: 100%;
  /* width: 100%; */
  border-radius: 3px;
}
.pubgiu-aboutTexxtQrap {
  margin-left: 15px;
}

.pubgiu-aboutTexxtQrap h5 {
  margin: 0;
  padding: 0;
  font-size: 17px;
}

.pubgiu-aboutTexxtQrap p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #002233;
  margin-bottom: 10px;
}

.copyToClipBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 630px) {
  .newspaperWrapBody {
    width: 180px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .paperImgHover {
    width: 180px;
    height: 250px;
  }
  .wraopNewpaperIMg {
    width: 180px;
    height: 250px;
  }

  .TNSAboutMagazine {
    width: 100%;
  }

  .TNSNewsPaperCover {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
}

@media (max-width: 425px) {
  .newspaperWrapBody {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 0;
    margin-right: 0;
  }
  .TNSNewsPaperCover {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .paperImgHover {
    width: 100%;
    height: 450px;
  }
  .wraopNewpaperIMg {
    width: 100%;
    height: 450px;
  }

  .TNSAboutMagazine {
    width: 100%;
  }
}

.sigle-book-col-two {
  margin-left: 20px;
}

.sigle-book-col-two .newspaperWrapBody {
  width: 150px;
  height: 227px;
  margin-right: 7px;
}
.sigle-book-col-two .newspaperWrapBody .wraopNewpaperIMg {
  width: 100% !important;
  height: 200px;
}

.sigle-book-col-two .subscribeToPublicationBtn button {
  width: 80px;
  height: 35px;
  font-size: 12px;
}

.paperImgHover {
  height: 100% !important;
  width: 100% !important;
}
.sPPW {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sigle-book-col-two {
  margin-left: 27px;
  width: 100%;
}

.singdfghjhyh .edition-type-home {
  margin-left: 2px !important;
  margin-bottom: 7px;
}
@media (max-width: 873px) {
  .single-book-section {
    flex-direction: column;
  }
  .sigle-book-col-one {
    margin-top: 1px;
  }
  .singdfghjhyh .edition-type-home {
    margin-bottom: 20px;
  }
  .sigle-book-col-two {
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  .sigle-book-col-two .newspaperWrapBody .wraopNewpaperIMg {
    width: 100%;
    height: 450px;
  }
  .sigle-book-col-two .newspaperWrapBody {
    width: 100%;
    height: 490px;
    margin-right: 7px;
  }

  .sigle-book-col-one {
    width: 100%;
  }
}
