@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,600|Tulpen+One&display=swap);
:root {
  --textViewPdfFont: pdfFont;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
html {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styleTable {
  border-radius: 5px;
}

.styleTable thead tr th {
  background-color: transparent !important;
  color: #334d6e !important;
  font-weight: 600;
}

.styleTable tbody tr .MuiTableCell-root {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

button:focus {
  border: none;
  outline: none;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.react-responsive-modal-modal {
  max-width: none !important;
  border-radius: 4px;
  /* display: flex; */
}
.section-wrap {
  padding: 6px 30px 60px 20px;
}

.new-section {
  margin: 40px 10px 30px 30px;
}

@media (max-width: 500px) {
  .new-section {
    margin: 10px 10px 30px 10px;
  }
}

.AppError {
  color: rgba(247, 36, 36, 0.842);
  font-size: 12px;
  display: block;
  font-style: italic;
}

select,
input,
button {
  outline: none !important;
  border: none;
}

select,
input {
  padding-left: 10px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #ee7f0d;
    --active-inner: white;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #ee7f0d;
    --border-hover: #ee7f0d;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 21px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}

input[type="radio"] {
  width: 15px !important;
  height: 15px !important;
}

/* input[type="checkbox"] {
  width: 20px !important;
  height: 15px !important;
} */

input[type="radio"]:after {
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  background: var(--active-inner);
  opacity: 0;
  transform: scale(var(--s, 0.7));
}

@font-face {
  font-family: generalFont;
  src: url(/static/media/Poppins-Regular.8081832f.ttf);
}

@font-face {
  font-family: boldFont;
  src: url(/static/media/Poppins-SemiBold.cce5625b.ttf);
}

* {
  font-family: generalFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: boldFont;
}

.hr-global {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.06);
}

.app-spinner:first-child div {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 50px;
}

.app-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-modal-wrap {
  background-color: #f2f2f2;
  padding: 0px 0 40px 0;
  padding-bottom: 23px;
}

.react-responsive-modal-modal {
  background: none !important;
  padding: 0 !important;
}

.addSubAccountBtn {
  width: 100%;
  background: white !important;
}

.addSubAccountBtn button {
  background: #ee7f0d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 16px;
  font-size: 13px;
  color: white;
}
.mbbfed9hyt {
  background: white !important;
  padding: 20px;
  letter-spacing: 0.75px;
  font-size: 13px !important;

  color: #00000080;
}
.xwijkds {
  color: black;
  font-weight: 600;
  padding-top: 29px;
  text-align: center;
  font-size: 20px;
}

.pay-now-button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 40px;
  max-width: 168px;
  width: 100%;
  color: white;
  font-weight: 600;
}

.fluterWvePayBTN {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.fluterWvePayBTN div button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  height: 60px;
  width: 168px;
  color: white;
}
.app-wrap-container {
  padding: 20px 20px 20px 20px;
}

.avatarProfileImageCrop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background-color: grey;
}

.avatarProfileImageCrop label {
  color: white !important;
}

.avatarProfileImageCrop div div input {
  border: 1px solid red !important;
}

.card {
  border: none !important;
  background-color: inherit !important;
  margin: 0;
  padding: 0;
}

.card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.datanotFound-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.datanotFound-wrap img {
  width: 80%;
  margin-top: 30px;
  border-radius: 5px;
}

.datanotFound-wrap p {
  text-align: center;
  margin-top: 20px;
  font-size: 19px;
  font-weight: 600;
}
.innerModalWrapWallet {
  /* width: fit-content !important; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white !important;
}

.css-2b097c-container {
  width: 90% !important;
  height: 45px !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}

.css-yk16xz-control {
  height: 100% !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}

.css-1okebmr-indicatorSeparator {
  height: 30px !important;
}

.css-tj5bde-Svg {
  position: relative !important;
  bottom: 5px !important;
}

.css-1wa3eu0-placeholder {
  position: absolute !important;
  top: 40% !important;
}

.errorNotFoundImg {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.errorNotFoundText {
  width: 400px;
}

.errorNotFoundTextImg img {
  width: 50%;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorNotFoundText p {
  font-size: 14px;
  font-weight: 600;
}

.errorNotFoundText span {
  font-size: 14px;
  font-weight: 400;
}
/* #holdon-overlay {
  background-color: #1847B1 !important;
} */
.sk-dot1,
.sk-dot2 {
  background-color: #ee7f0d !important;
}

.sk-dot2,
.sk-dot2 {
  background-color: #ee7f0d !important;
}
a {
  color: black !important;
}
/* .rpv-toolbar-right :nth-child(3n + 2) {
  display: none !important;
} */

/* .rpv-default-layout-sidebar-header {
  display: none !important;
} */
.rpv-open-button {
  display: none !important;
}

.rpv-toolbar-right .rpv-toolbar-item:nth-child(3n + 3) {
  display: none !important;
}

.rpv-toolbar-right .rpv-toolbar-item:nth-child(3n + 4) {
  display: none !important;
}

.rpv-default-layout-body {
  top: 50px !important;
}

.rpv-default-layout-sidebar-headers
  .rpv-default-layout-sidebar-header:nth-of-type(2) {
  display: none;
}

.rpv-default-layout-sidebar-headers
  .rpv-default-layout-sidebar-header:nth-of-type(3) {
  display: none;
}

@media (max-width: 500px) {
  .rpv-toolbar-left .rpv-toolbar-item:nth-child(3n + 3) {
    display: none !important;
  }
}
/* .rpv-toolbar-right {
} */
/* .rpv-open-button {
  display: none !important;
} */

.rpv-default-layout-toolbar {
  position: fixed;
  width: 100vw;
  top: 75px;
  z-index: 12;
}

.rpv-core-progress-bar-inner {
  background-color: #ee7f0d !important;
}

.rpv-default-layout-sidebar-tabs {
}

/* .rpv-toolbar-item {
  display: none !important;
} */

@media (max-width: 575px) {
  .inner-modal-wrap {
    width: 90vw !important;
  }
}

/* .rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 350px !important;
} */

/* @media (max-width: 700px) {
  .rpv-thumbnail-list {
    width: 100px !important;
  }

  .rpv-default-layout-sidebar-headers {
    display: none !important;
  }
} */

/* .rpv-core-page-layer,
.rpv-core-page-size-calculator {
  width: 100vw !important;
}
.rpv-core-canvas-layer {
  width: 100vw !important;
}
.rpv-core-canvas-layer canvas {
  width: 100vw !important;
} */

.fullPageErro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.fullPageErro img {
  width: 500px;
  height: 300px;
}

.fullPageErro p {
  margin-top: 20px;
  font-size: 19px;
}

.inner-modal-wrap-mLogin {
  width: 400px;
}

.inner-modal-wrap-mLogin .inPutHomeWrapP {
  width: 100%;
}

.inner-modal-wrap-mLogin .login-wrap-home-input {
  min-width: 100%;
}

.inner-modal-wrap-mLogin .submit-login-details-home button {
  width: 100%;
}

.login-modal-header-text {
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 23px;
}

.logoutBtnLoginModal {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logoutBtnLoginModal span {
  color: rgba(255, 0, 0, 0.76);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.wrapTransationTable {
  width: 90%;
  border: 1px solid rgba(128, 128, 128, 0.507);
  border-radius: 5px;
  margin-top: 20px;
}
table thead tr th {
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

table tbody tr:hover {
  background-color: rgba(128, 128, 128, 0.13) !important;
  cursor: pointer !important;
}

table tbody tr {
  color: #000000 !important;
  font-size: 12px !important;
  white-space: nowrap;
}

@media (max-width: 437px) {
  .inner-modal-wrap-mLogin {
    width: 90vw;
  }
}

.termsOfUsePdfPage {
  position: relative;
  width: 100vw;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.termsOfUsePdfPage-inner {
  position: fixed;
  z-index: 20;
  left: 50%;
  width: 27px;
  height: 27px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: red;
  /* -webkit-animation: bounce 5s infinite alternate; */
  /* animation: bounce 0.17s infinite alternate; */
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 1.7s;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  display: none;
}

.termsOfUsePdfPage-inner img {
  width: 90%;
  height: 90%;
}

.swal-button--confirm {
  background-color: #ee7f0d !important;
  border: none !important;
  outline: none !important;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .termsOfUsePdfPage-inner {
    width: 35px;
    height: 35px;
  }
}

@font-face {
  font-family: pdfFont;
  src: url(/static/media/Merriweather-Bold.7b45103b.ttf);
}

.addSubAccountHeader {
  font-size: 15px !important;
  padding: 20px 20px 0px 20px;
  color: #6e7191;
}

.cancelSub {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.cancelSub svg {
  width: 400px;
  height: 400px;
}

.cancelSub p {
  width: 400px;
  text-align: center;
}

.cancelSub button {
  background-color: #ee7f0d;
  border: none;
  padding: 10px;
  width: 150px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
}

.fund-ur-wallet-type {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  border-radius: 5px;
}

.fundWalletMethodLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fundWalletMethods {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.activeRouteFund {
  text-align: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.activeRouteFundAfter::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ee7f0d;
  margin-top: 6px;
  text-align: center;
}

.walletTransfeerDetails {
  text-align: center;
  margin-top: 10px;
  padding: 10px 0px 40px 0px;
}

.fundWalletHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 90%;
}

.fundWalletHeader button {
  background-color: rgba(231, 21, 21, 0.788);
  color: white;
  height: 10px;
  height: 34px;
  width: 93px;
  border-radius: 4px;
}

.fundWalletHeader p {
  font-size: 20px;
}
.tranferDoneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tranferDoneBtn button {
  background-color: #ee7f0d;
  color: white;
  height: 10px;
  height: 34px;
  width: 98px;
  border-radius: 4px;
  font-size: 13px;
}

.fundUssdBankDetails {
  margin-top: 10px;
}

.fundUssdBankDetails select {
  height: 45px;
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.1);
  width: 80%;
}

.walletBalanceDetailsuyf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 10px;
}

.fundWalletMethodsHeader {
  margin-top: 14px;
  text-align: center;
  font-size: 16px;
  color: #002233;
  font-weight: 500;
}

.walletActionsButton {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.walletActionsButton button img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.walletActionsButton button {
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.walletActionsButton button:nth-child(3n + 1) {
  background-color: #ee7f0d;

  border-radius: 3px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
}

.walletActionsButton button:nth-child(3n + 2) {
  background-color: #002333;

  border-radius: 3px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
}

.walletActionsButton button:nth-child(3n + 3) {
  background-color: #eff0f7;
  border-radius: 3px;
  color: #002333;
  margin-right: 5px;
  font-size: 12px;
}

.fundvjgahdh {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  color: #002233;
  padding: 5px 30px 10px 30px;
}

.xModalT {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin-top: 10px;
}
.xModalT i {
  cursor: pointer;
}

.userBankDrtailswrap {
  padding: 10px;
  background: rgba(44, 44, 44, 0.1);
}
.uTrD p {
  padding: 0;
  margin: 0;
  font-size: 17px;
  color: #002233;
}
.uTrD p:nth-child(3n + 2) {
  color: #ee7f0d;
  margin-top: 5px;
  margin-bottom: 5px;
}

.inner-modal-wrap-tr {
  width: 420px;
  background-color: white !important;
}

.walletBalanceDetailsuyf p {
  margin-right: 15px;
}

.fundWalletTInput input {
  border-bottom: 1px solid rgba(102, 102, 102, 0.45);
  font-size: 14px;
  text-align: center;
  color: grey;
}
.walletTransfeerDetailsCard {
  padding-bottom: 0;
}

.iq2hk {
  margin: 0;
  padding: 0;
  margin-top: 15px;
}

.walletActionsButtonCard button {
  margin-bottom: 0;
}

.walletActionsButtonCard button:nth-child(3n + 3) {
  margin-top: 15px;
}
.walletActionsButtonCard {
  padding-bottom: 20px;
}

@media (max-width: 485px) {
  .inner-modal-wrap-tr {
    width: 90vw;
  }

  .walletActionsButton button:nth-child(3n + 3) {
    margin-top: 10px;
  }
}

.freeReadModalImWrap{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.freeReadModalImWrap img{
  width: 90%;
}
.login-wrap-home {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #ee7f0c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 70px;
}

.login-wrap-home .login-header-text {
  font-weight: 500;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
  line-height: 30px;
}

.login-wrap-home-input {
  min-width: 480px;
  max-width: 90vw;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 50px;
}
.createAccountLogo {
  margin-bottom: 30px;
}
.login-form-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-col-one-home button {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 270px;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.login-col-one-home button img {
  margin-right: 10px;
}

.login-col-three-home input {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.submit-login-details-home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.submit-login-details-home button {
  background: #ee7f0d;
  border-radius: 5px;
  width: 90%;
  height: 50px;
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.forgot-password-wrap-home {
  display: flex;
  justify-content: center;
}

.forgot-password-wrap-home a {
  margin-right: 17px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.75px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #666666;
}

.login-col-three-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.login-col-three-home input {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.create-account-wrap {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  background-color: white;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  padding-left: 90px;
}

.create-account-col-one-wrap {
  width: 27%;
}

.create-account-col-two-wrap {
  width: 50%;
  margin-left: 125px;
}

.tns-create-account-logo {
  margin-bottom: 30px;
}

.offer-tns-home-create-acc {
  margin-bottom: 30px;
}

.offer-tns-home-create-acc h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #002333;
  margin: 0;
}

.offer-tns-home-create-acc h1 img {
  margin-right: 5px;
}

.offer-tns-home-create-acc p {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #4e4b66;
  margin-left: 25px;
  padding-top: 10px;
}
.create-account-col-two-wrap h1 {
  font-weight: 600;
  font-size: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  color: #002333;
}

.account-type-home {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 23px;
}
.account-type-home span {
  margin-right: 20px;
  color: #6e7191;
  font-size: 13px;
}

.account-type-home span input {
  margin-left: 6px;
  margin-top: 5px;
}

.input-wrap-create-acc {
  /* margin-bottom: 20px; */
}

.input-wrap-create-acc label {
  font-size: 14px;
  letter-spacing: 0.75px;
  color: #6e7191;
  margin-bottom: 4px;
}

.input-wrap-create-acc .input-create-acc input::placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input {
  font-size: 13px;
  width: 350px;
  height: 45px;

  background: #eff0f7;
  border-radius: 8px;
}
.input-wrap-create-acc .PhoneInput {
  font-size: 13px;
  width: 90% !important;
  height: 45px;
  padding-left: 10px !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}
.PhoneInputInput {
  background-color: transparent !important;
}
.create-acc-newlette h1 {
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  letter-spacing: 0.75px;
  color: #6e7191;
  margin-bottom: 11px;
}

.create-acc-newlette p {
  font-weight: 300;
  font-size: 11px;
  color: #6e7191;
  margin: 0;
  /* line-height: 0px; */
  padding-top: 0px;
}

.create-acc-newlette p input {
  margin-right: 10px;
  margin-top: -5px;
}

.submit-login-createAcc {
  justify-content: flex-start;
  width: 100%;
}

.submit-login-createAcc button {
  justify-content: flex-start;
  width: 350px;
}
.loginAppLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  margin-bottom: 31px;
  margin-top: -32px;
}
.error-container {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -20px;
}
/* RESPONSIVE DESIGN */

@media (max-width: 1005px) {
  .login-wrap-home-input {
    width: 90vw;
  }
  .login-col-three-home input {
    width: 90%;
  }
  .submit-login-details-home button {
    width: 90%;
  }

  .error-container {
    width: 85%;
  }
}

@media (max-width: 682px) {
  .login-form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 705px) {
  .hideOnDesktopCreateAccountLogo {
    display: none !important;
  }
}
@media (max-width: 705px) {
  .create-account-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-left: 10px;
  }
  .create-account-wrap {
    margin-top: 30px;
  }
  .create-account-col-two-wrap .firstHeaderCr {
    text-align: center;
    justify-content: center;
    margin-top: 30px;
    color: #ee7f0d;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .hdCreM {
    display: none !important;
  }

  .pPMr {
    top: 0 !important;
    margin-left: 8px;
  }
  .accountTypehfo {
    display: flex;
    justify-content: space-between;

    margin-bottom: 25px;
    margin-top: 32px;
  }

  .account-type-home span {
    margin-right: 0;
  }
  .input-wrap-create-acc .input-create-acc input {
    width: 100%;
  }

  .togglePasscreAcc {
    width: 100% !important;
    position: relative;
  }

  .css-2b097c-container {
    width: 90% !important;
    height: 45px !important;
    background: #eff0f7 !important;
    border-radius: 8px;
  }
  .submit-login-details-home button {
    width: 100%;
  }
  .forgot-password-wrap-home {
    display: flex;
    justify-content: center !important;
    align-items: center;
    margin: auto;
    margin-bottom: 35px;
  }
  .create-account-col-one-wrap {
    display: none;
  }

  .create-account-col-two-wrap {
    margin-left: 0;
    width: 90%;
  }
}

.toggleViewPassword {
  position: relative;
  width: 90%;
}

.toggleViewPassword i {
  position: absolute;
  top: -57px;
  right: -20px;
  font-size: 18px;
  cursor: pointer;
  color: #ee7f0d;
}

.togglePasscreAcc {
  width: 350px;
  position: relative;
}

.togglePasscreAcc i {
  position: absolute;
  right: 0;
  top: -29px;
  font-size: 18px;
  cursor: pointer;
  color: #ee7f0d;
}

.inPutHomeWrapP {
  width: 90%;
}

@media (max-width: 600px) {
  .inPutHomeWrapP {
    width: 100%;
  }
  .login-wrap-home-input {
    min-width: 90vw;
  }

  .login-col-three-home input {
    width: 100%;
  }

  .toggleViewPassword i {
    position: absolute;
    top: -57px;
    right: 0 !important;
    font-size: 18px;
    cursor: pointer;
    color: #ee7f0d;
  }
}

.forgot-password-wrap-home a {
  color: white !important;
}

@media (max-width: 400px) {
  .forgot-password-wrap-home {
    flex-direction: column;
  }
  .forgot-password-wrap-home a {
    margin-bottom: 7px;
  }
}

/* 
LOGIN PAGE DESIGN V2 */

.login-wrap-v2 {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: white;
  width: 100vw;
}

.login-wrap-v2-col1 {
  height: 100%;
  width: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.507), rgba(0, 0, 0, 0.3)),
    url(/static/media/loginBanner2.34aae8c3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px 20px 40px;
}

.heroTextSection {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  height: 75%;
}

.heroTextSection h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 51px;
  color: #ffffff;
}

.heroTextSection p {
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #fffffffa;
  margin-top: 20px;
}

.heroTextSection button {
  width: 170px;
  height: 50px;
  background-color: rgb(238, 127, 13);
  color: white;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 400ms ease;
  font-size: 15px;
}

.heroTextSection button:hover {
  transform: translateY(-10px);
}

.login-wrap-v2-col2 {
  height: 100%;
  width: 50%;
  padding: 40px 20px 20px 40px;
  overflow: hidden;
  overflow-y: scroll;
}

.login-wrap-v2-col2 ::-webkit-scrollbar {
  opacity: 0 !important;
}

.signUpOptonLogin {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.signUpOptonLogin p {
  font-size: 13.5px;
  cursor: pointer;
}

.signUpOptonLogin p span a {
  font-size: 13.5px;
  color: rgb(238, 127, 13) !important;
  font-weight: 600;
}

.login-wrapv2-main {
  border: 1px solid #f7f7fc;
  width: 78%;
  padding: 40px 40px 50px 40px;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 50px;
}

.login-wrapv2-main .login-txt-header {
  font-size: 27px;
  font-weight: 600;
  color: black;
}

.inputWrap-v2 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.inputWrap-v2 input {
  background: #eff0f7;
  border: 1px solid rgba(189, 189, 189, 0.44);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  width: 90%;
  font-size: 12px;
  border: none;
  color: #a0a3bd;
}

.inputWrap-v2 label {
  font-size: 14px;
  color: #6e7191;
}

.login-wrapv2-main {
  margin-top: 30px;
}

.forgotPasswordOptionv2 {
  margin-top: 13px;
}
.forgotPasswordOptionv2 p a {
  color: #ee7f0d !important;
  font-size: 13px;
  cursor: pointer;
}

.loginBtnV2 {
  width: 100%;
  margin-top: 23px;
}

.loginBtnV2 button {
  background-color: #ee7f0d;
  height: 45px;
  width: 90%;
  border-radius: 4px;
  color: #ffff;
  transition: all 300ms ease;
}

.loginBtnV2 button:hover {
  filter: drop-shadow(6px 5px 6px rgba(128, 128, 128, 0.651));
}

.mobileViewLogoV2 {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.mobileViewLogoV2 img {
  width: 200px;
}

@media (min-width: 1018px) {
  .mobileViewLogoV2 {
    display: none;
  }
}

@media (max-width: 1018px) {
  .login-wrapv2-main {
    width: 95%;
    padding: 20px;
  }

  .signUpOptonLogin {
    margin-top: 20px;
    padding-right: 20px;
  }

  .login-wrap-v2-col1 {
    display: none;
  }

  .login-wrap-v2-col2 {
    width: 100%;
  }
}

.haveanAccountV2Op {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.haveanAccountV2Op p {
  color: black;
  font-size: 14px;
}
.haveanAccountV2Op p a {
  color: #ee7f0d !important;
  font-weight: 600;
}

@media (max-width: 654px) {
  .login-wrapv2-main {
    width: 100%;
    padding: 20px;
  }

  .login-wrap-v2-col2 {
    padding: 0;
  }

  .login-wrapv2-main {
    padding: 20px 15px 10px 15px;
  }

  .inputWrap-v2 input {
    width: 100%;
  }

  .input-wrap-create-acc .PhoneInput {
    width: 100% !important;
  }

  .css-2b097c-container {
    width: 100% !important;
  }

  .loginBtnV2 button {
    width: 100% !important;
  }
}

.navbar-wrap {
  background-color: white;
  height: 80px;
  position: fixed;
  width: 100vw;
  top: 74px;
  z-index: 40;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.103));
}

.navbar-position {
  position: relative;
}
.navbar-pad {
  padding: 10px 20px 10px 0;
}

.sidebar-wrap {
  height: calc(100vh - -6px);
  bottom: 0;
  background: rgba(238, 127, 13, 0.14);
  width: 240px;
  position: fixed;
  z-index: 55;
}

.backgroundImage-wrap {
  background-image: url(/static/media/body.0d363faa.jpeg);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  /* background-color: #f8f8f8; */
  background-position: center;
  position: fixed;
  z-index: 0;
  top: 0;
  background-color: #e8e9eb;
}

.app-container {
  position: relative;
  left: 220px;
  width: calc(100vw - 275px);
  top: -7px;
  padding-bottom: 30px;
  padding: 0;
}

.sidebar-list {
  list-style-type: none;
  padding-left: 10px;
  padding-top: 19px;
}

.sidebar-list li {
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 9px;
}

.sidebar-list li .inner-navlist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sidebar-list li .inner-navlist div:first-child img {
  width: 20px;
}

.sidebar-list li .inner-navlist div:first-child .inner-navHeader {
  font-weight: 600;
  font-size: 14px;
  color: #002233;
  margin-left: 5px;
}

.sidebar-radio {
  width: 20px !important;
  height: 20px !important;
}

.sidebar-radio[type="radio"]:after {
  width: 20px !important;
  height: 20px !important;
  /* margin-bottom: 4px; */
  top: -1px !important;
  left: -1px !important;
}

.sidebar-datepicker input {
  border: 1px solid rgba(128, 128, 128, 0.76);
  padding: 10px;
  border-radius: 3px;
}

.sidebar-list li .inner-navlist div:last-child i {
  font-size: 15px;
}

.innerList-drop-down {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.innerList-drop-down span {
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1.3px;
}

.innerList-drop-down span input {
  margin-right: 10px;
}

.sidebar-hr {
  height: 0px;
  position: relative;
  left: -11px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.029);
}

.active-dot-sidebar {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(238, 127, 13);
}

.sidebar-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sidebar-chf5 {
  display: flex;
}

.sidebar-i {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.active-sidebar-route {
  background-color: white;
  border-right: 1px solid rgb(238, 127, 13);
}

.sidbar-link-total {
  font-weight: 600;
}

.sidbar-link-total span {
  font-weight: 400;
  font-size: 13px;
}

.sidebarLogo {
  margin-top: -18px;
  padding-top: 0;
  margin-bottom: 20px;
}

.sidebarLogo img {
  width: 110px;
  margin-top: 20px;
}

.navbar-pad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-logo-wrap-layout-page {
  margin-right: auto;
  width: 65%;
}

.app-logo-wrap-layout-page img {
  width: 129px;
  /* height: 40px; */
  margin-right: 29px;
}

.avatarIcaon-nav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nav-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-actions ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.nav-actions ul li {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-left: 10px;
}

.nav-actions ul li:hover {
  color: orange;
}
.nav_search_app button {
  background-color: #ee7f0d;
  color: white;
  width: 50px;
  border: none;
  height: 40px;
  border-radius: 0px 5px 5px 0;
}

.nav_search_app {
  width: 100%;
  background-color: red;
  flex: 1 1;
  margin-left: 20px;
}

.nav_search_app input {
  color: black;
  border: 1px solid #e4e4e4;
  height: 40px;
  padding-left: 10px;
  width: 65%;
}

.nav_search_app select {
  color: black;
  border: 1px solid #e4e4e4;
  height: 40px;
  border-radius: 5px 0px 0px 5px;
  background: #e1e1e1;
}

.nav-actions ul li,
a {
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 24px;
  color: #002333;
}

.nav-user-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nav-user-icon span {
  margin-right: 10px;
}

.nav_wallet_li span {
  line-height: 14px;
  margin-top: 8px;
  margin-left: 5px;
}

.nav_wallet_li i {
  font-size: 18px;
  color: #002333 !important;
}

.harmburger_menu-nav i {
  color: #002333 !important;
  position: relative;
  right: 28px;
  font-size: 25px;
  top: 8px;
  cursor: pointer;
}

.responsiveSideBar {
  height: 100vh;
  background-color: transparent;
  width: 200px;
  position: fixed;
  z-index: 55;
}

/* @media (min-width: 906px) { */
.sidebar-wrap {
  background-color: white;
  filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
}

.navbar-wrap {
  background-color: white;
  filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
}

.app-container {
  position: relative;
  left: 0px;
  width: 100vw;
  top: 74px;
  overflow-x: hidden;
}

.navbar-wrap {
  height: 75px;
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  padding-left: 35px;
  z-index: 40;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.active-sidebar-route {
  background-color: #0a1531;
  color: white;
}
.navbar-pad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.backgroundImage-wrap {
  display: none;
}

#App .navRoutes {
  margin-right: auto;
  padding-left: 100px;
}

#App .navRoutesActions {
  padding-right: 20px;
}

#AppWrap .generalPadding {
  padding-left: 20px;
  padding-top: 40px;
}

.ham-toggle {
  display: block;
}

#App {
  justify-content: left;
}

#App .navRoutes {
  display: none;
}

#App .navRoutesActions {
  margin-left: auto;
}

#AppWrap {
  width: 100vw;
  background: #e5e5e5;
  height: calc(100vh - 75px);
}

#AppWrap .generalPadding {
  padding-left: 20px;
  padding-top: 40px;
}

.responsiveSideBar {
  width: 0;
  z-index: 8;
}

.responsiveSideBarOpen {
  animation: showbar 500ms ease-in forwards;
}

.AppCloseSidebar {
  animation: hidebar 500ms ease-out forwards;
}

@keyframes showbar {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes hidebar {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-271px);
  }
}
.initSidebarState {
  transform: translateX(-271px);
}
#ApiHeader {
  width: 95%;
}

.navRoutesLogo {
  margin-left: 0 !important;
}
/* } */

.ApisideDropdown {
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
  margin-top: 10px;
  margin-top: 30px;
  margin-left: 0;
}

.ApisideDropdown .sidebarHeader-list {
  font-family: Gotham;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border: 0.277929px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  border-left: none;
  border-right: none;
}

.subSidbar-Details {
  margin-top: 10px;
  list-style-type: none;
}

.subSidbar-Details .subSidbar-Details-child {
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  border: none;
  color: white;
  cursor: pointer;
}

.ham-toggle {
  display: none;
}

@media (max-width: 611px) {
}

@media (max-width: 325px) {
  #App .navRoutesActions button:last-child {
    width: 60px;
    margin-left: 10px;
  }

  #App .navRoutesActions button:first-child {
    width: 60px;
  }

  #App .navRoutesLogo img {
    width: 70px;
    margin-left: 13px;
  }

  #AppWrap .generalPadding {
    padding-top: 10px;
  }
}

html,
body {
  height: 100%;
  min-height: 100%;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.page-wrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper button {
  padding: 20px;
  border: none;
  background: #d5d8e7;
  position: relative;
  outline: none;
  border-radius: 5px;
  color: #292d48;
  font-size: 18px;
}
.page-wrapper button .cart-item {
  position: absolute;
  height: 24px;
  width: 24px;
  top: -10px;
  right: -10px;
}
.page-wrapper button .cart-item:before {
  content: "1";
  display: block;
  line-height: 24px;
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-weight: 600;
  background: #2bd156;
  color: white;
  border-radius: 20px;
  text-align: center;
}
.page-wrapper button.sendtocart .cart-item {
  display: block;
  animation: xAxis 1s forwards cubic-bezier(1, 0.44, 0.84, 0.165);
}
.page-wrapper button.sendtocart .cart-item:before {
  animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}
.cart {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: #292d48;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.cart i {
  font-size: 25px;
  color: white;
}
.cart:before {
  content: attr(data-totalitems);
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -12px;
  right: -12px;
  background: #2bd156;
  line-height: 24px;
  padding: 0 5px;
  height: 24px;
  min-width: 24px;
  color: white;
  text-align: center;
  border-radius: 24px;
}
.cart.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes xAxis {
  100% {
    transform: translateX(calc(50vw - 105px));
  }
}
@keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh + 75px));
  }
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.navbar-login-access a {
  text-decoration: none;
  font-size: 13px !important;
  font-weight: 600;
}

.navbar-login-access a:hover {
  color: orange;
}

#app-router-home {
  position: relative;
  top: 73px;
  margin-left: 0;
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding-left: 25px;
  height: 75px;
  overflow: hidden;
  overflow-x: scroll;
}

#app-router-home .app-router-homeharmburger {
  width: 10%;
  color: #002233;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 50px;
  align-items: center;
}

#app-router-home .app-router-homeharmburger i {
  margin-right: 7px;
  color: #002233;
}

#app-router-home .center-routers {
  display: flex;
  justify-content: center;
  width: 80%;
}

#app-router-home .center-routers span {
  margin-right: 15px;
  cursor: pointer;
  font-size: 14px;
}

#app-router-home .center-routers .activeNews {
  font-weight: 600;
  color: #14142b;
  display: flex;
}

#app-router-home .center-routers .inactiveNews {
  color: #6e7191;
  display: flex;
}

.sidebar-header {
  color: black;
  display: flex;
  justify-content: space-between;
}

.sidebar-header img {
  margin-right: 8px;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.sidebar-header span {
  font-weight: 600;
  font-size: 14px;
  color: #002233;
}

.profile-dropdown-nav {
  position: fixed;
  right: 51px;
  top: 62px;
  z-index: 50;
  width: 400px;
  height: 200px;
  width: 309px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.nav-user-drop67hn {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-top: 30px;
}

.nav-user-drop67hn img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.nav-user-drop67hn span {
  display: flex;
  flex-direction: column;
}

.account-ausername,
.account-auseremail {
  color: #6e7191;
  font-size: 10px;
}

.account-btn-acc {
  background: #ee7f0d;
  border-radius: 40px;
  width: 88px;
  height: 33px;
  color: white;
  font-size: 11px;
  margin-top: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.navbar-dropdownlinks {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.navbar-dropdownlinks span {
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #444444;
  cursor: pointer;
}

.navbar-dropdownlinks span img {
  /* width: 20px; */
  margin-right: 8px !important;
  /* height: 20px; */
}
.gjfu6ghs2d {
  color: black;
  font-weight: 600;
  margin-top: 5px;
}

.sidebar-quick-access {
  background-color: #ee7f0d !important;
  position: relative;
  left: -10px;
  width: 105%;
  border-radius: 0 !important;
  color: white !important;
}

.sidebar-quick-access .inner-navHeader {
  margin-left: 25px !important;
  color: white !important;
}

.sidebar-quick-access-link {
  background-color: #ebeff2 !important;
  position: relative;
  left: -10px;
  width: 105%;
  border-radius: 0 !important;
  color: white !important;
}

.sidebar-quick-access-link .inner-navHeader {
  margin-left: 25px !important;
  color: #002233 !important;
}

.show-nav-border {
  border-right: 1px dashed rgba(0, 0, 0, 0.25);
  padding-right: 16px;
}

.selectCategoriesWrapHome {
  border: 1px solid rgba(128, 128, 128, 0.219);
  padding: 5px;
  border-radius: 4px;
}

@media (max-width: 1354px) {
  .hidenavbrsearch {
    display: none;
  }
}

@media (max-width: 554px) {
  .hidemycartAndWalllet {
    display: none !important;
  }
  .navbar-pad {
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-wrap {
    padding-left: 5px;
    padding-right: 0;
  }
  .remjegdwy {
    margin-right: 0 !important;
  }
  .nav-actions ul li,
  a {
    margin-right: 0 !important;
  }
  .nav-user-icon span {
    margin-right: 5px !important;
  }
}

@media (min-width: 554px) {
  .hideOnDesk {
    display: none !important;
  }
}

@media (max-width: 581px) {
  .profile-dropdown-nav {
    right: 0;
  }
  #app-router-home .center-routers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
}

@media (max-width: 459px) {
  .hideFilterText {
    display: none !important;
  }
  #app-router-home .center-routers .inactiveNews,
  .activeNews {
    font-size: 12px !important;
  }
}

.hjsgt i {
  font-weight: 600;
  color: black;
  font-size: 20px;
  margin-right: 7px;
}

.hjsgt img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

@media (max-width: 693px) {
  .hdPoNmobile {
    display: none !important;
  }
}

.searchMainDrop {
  position: absolute;
  left: 200px;
  width: 380px;
  height: 500px;
  background-color: white;
  z-index: 6;
  top: 85px;
  filter: drop-shadow(20px 8px 20px rgba(128, 128, 128, 0.521));
  border-radius: 5px;
  padding: 20px 12px 12px 12px;
  overflow: hidden;
  overflow-y: scroll;
}

.searchMainDrop::-webkit-scrollbar {
  opacity: 0;
}

.searchMainDrop ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchMainDrop ul li {
  margin-bottom: 10px;
  padding: 15px;
  width: 99%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: 200ms ease forwards;
}

.searchMainDrop ul li:hover {
  transform: translateY(-5px);
  border-bottom: 1px solid #ee7e0d70;
}
.searchMainDrop ul li:nth-child(odd) {
  background-color: rgba(128, 128, 128, 0.123);
}

.slide-wrap-home {
  /* background: rgb(238 127 13 / 34%); */
  height: 261px;
  width: 100%;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
  height: 261px !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: transparent !important;
}

.home-slide-wrap {
  display: flex;
  justify-content: space-around;
}

.home-slide-wrap .home-slide-text {
  width: 50%;
  margin-right: auto;
  padding-left: 30px;
}

.home-slide-text {
  text-align: left;
  padding-top: 40px;
}

.home-slide-text h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #002233;
}

.home-slide-text p {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4e4b66;
  margin-top: 17px;
  margin-bottom: 17px;
}

.home-slide-text button {
  background: #ee7f0d;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
  color: white;
}

@media (max-width: 823px) {
  .home-slide-text h1 {
    padding-left: 23px;
  }
  .home-slide-text p {
    color: #4e4b66;
    margin-top: 4px;
    margin-bottom: 17px;
    margin-left: 27px;
  }

  .home-slide-text button {
    margin-left: 26px;
  }

  .slide-wrap-home {
    height: 420px;
  }
}
@media (max-width: 620px) {
  .hideSlideOn-md-screen {
    display: none !important;
  }
}

@media (min-width: 620px) {
  .mobile-slide {
    display: none !important;
  }

  .mobile-slide-hideMobile {
    display: none;
  }
}
@media (max-width: 620px) {
  .slide-wrap-home {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  slider-wrapper {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .mobile-slide {
    background-image: url(/static/media/home-slide.a7371ae2.png);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.85),
        rgba(255, 252, 245, 0.85)
      ),
      url(/static/media/home-slide.a7371ae2.png) no-repeat top center;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-position: center;
  }

  .mobile-slide h1 {
    font-size: 22px;
    /* color: white; */
  }

  .mobile-slide p {
    font-size: 17px;
    margin-top: 8px;
  }
}

.home-slide-mobileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-slide-mobileBtn button {
  width: 120px;
  height: 40px;
  background-color: #ee7f0d;
  color: white;
  border-radius: 3px;
}

.adsAndNewCardWrap {
  display: flex;
  width: 100%;
  padding-right: 30px;
}

.adsNewSwrap {
  width: 75%;
}

.home-ads {
  width: 25%;
  height: 700px;
  background-color: white;
  margin-top: 50px;
  border: 1px solid #f3d5b3;
  border-radius: 5px;
  padding: 15px 10px 12px 10px;
}

.homePageHeaderAds {
  text-align: center;
  font-size: 22px;
}

.adsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.adsContainer img {
  width: 90%;
  margin-bottom: 10px;
  height: 200px;
  border-radius: 3px;
  cursor: pointer;
}
.waletBalanceModalWrap {
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.waletBalanceModalWrap .kyudry {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: #002233;
  margin-right: 15px;
}

.waletBalanceModalWrap .jhddsr {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  color: #f3a658;
  font-weight: 600;
  font-size: 15px;
}
.loadingSvgHomeWrap {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.svgHomeLoader {
  width: 280px;
  height: 400px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

@media (max-width: 425px) {
  .svgHomeLoader {
    width: 100%;
    margin-right: 0 !important;
  }
}

.news-wrap-home {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 7px;
}

.show-new-home {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
}

.show-new-home-as-grid {
  --auto-grid-min-size: 200px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  width: 100%;

  grid-gap: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 2px;
  opacity: 0;
  visibility: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
  opacity: 0;
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ee7f0d;
  border-radius: 1px;
  opacity: 0;
  visibility: hidden;
}

.newsCard {
  position: relative;
  width: 219px;
  height: 255px;
  /* margin: 5rem auto; */
  background-color: #fff;
  color: #fff;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: -7px;
  overflow: hidden;
}
.news-Slide-up {
  margin-right: 20px;
}

.newsCard .magazin-img {
  width: 232px;
  height: 100%;
  left: -6px;
  position: relative;
}

.like-icon-wrap {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  cursor: pointer;
}

.add-to-cart-btn {
  position: absolute;
  width: 218px;
  height: 100%;
  top: -41px;
  left: 1px;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
}

.add-to-cart-btn:hover {
  background: rgba(0, 0, 0, 0.521);
}

.add-to-cart-btn button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  width: 110px;
  height: 42px;
  border-radius: 27px;
  font-size: 14px;
  background: #ee7f0d;
  color: white;
  border: none;
  outline: none;
}

.add-to-cart-btn:hover button {
  opacity: 1;
  transform: translateX(-20px);
  transition: transform 0.4s, opacity 0.1s;
}

.publicationSubscribed {
  position: relative;
}

.publicationSubscribed button {
  position: absolute;
  background: #ee7f0c;
  right: 15px;
  bottom: 28px;
  width: 80px;
  font-size: 13px;
  height: 35px;
  color: white;
  border-radius: 4px;
  transition: all ease 500ms;
}

.publicationSubscribed button:hover {
  transition: all ease 500ms;
  transform: translateY(-7px);
}

.news-date-sec {
  position: absolute;
  bottom: -40px;
  width: 100%;
  background: #ee7f0c;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 0 0px 5px 5px;
}

.magazine-title-home p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}

.magazine-comment-home {
  white-space: nowrap;
}

.magazine-title-home p:last-child {
  font-size: 9px;
  color: white;
  white-space: nowrap;
}

.magazine-comment-home span {
  margin-left: 2px;
  margin-right: 6px;
  font-size: 12px;
}

.magazine-comment-home img {
  cursor: pointer;
}

.edition-type-home {
  font-size: 25px;
  margin-left: 5px;
  font-weight: 600;
  font-size: 24px;
  color: #002233;
  margin-top: 10px;
  margin-bottom: 23px;
}

.single-book-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.sigle-book-col-one {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 440px;
  margin-top: 40px;
  background: #f3f3f3;
  padding-bottom: 10px;
  border-radius: 5px;
}

.sigle-book-col-one .magazin-img-single {
  margin: 10px 5px 10px 7px;
}

.single-book-name {
  color: white;
  font-size: 16px;
  margin-left: 10px;
  width: 245px;
  font-size: 12px;
}

.sigle-book-col-one .single-book-sub-btn {
  font-size: 12px;
  background-color: orange;
  padding: 10px;
  border: none;
  margin-left: 10px;
  border-radius: 4px;
  color: white;
  width: 84px;
  height: 37px;
}

.sigle-book-col-two {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 72%;
  overflow: hidden;
  margin-right: 27px;
  /* background-color: orange; */
}

.wraopNewpaperIMg {
  background-color: #f3f3f3;
  width: 280px;
  height: 368px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 15px 15px 0px 0;
  padding: 5px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.paperImgNew {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  margin-top: 10px;
}

.paperImgNew img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 5px;
}

.view_paper_card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
}

.TNSNewsPaperCover {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}

.paperImgHover {
  width: 280px;
  height: 364px;
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 15px 15px 0px 0;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
}

.paperImgHover:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

.newspaperWrapBody {
  width: 280px;
  height: 413px;
  margin-right: 10px;
  margin-bottom: 13px;
  justify-self: center;
  overflow: hidden;
}

.free-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ee7f0c;
  width: 90px;
  height: 40px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 4px;
  border-bottom-left-radius: 15px;
  z-index: 10;
}

.inactive-button {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e02909;
  width: 120px;
  height: 40px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  border-bottom-right-radius: 15px;
  z-index: 10;
}

.TNSAboutMagazine {
  background-color: #f3f3f3;
  width: 100%;
  padding-left: 12px;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.subscribeToPublicationBtn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.subscribeToPublicationBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.followEditionIcon {
  margin-right: 10px;
  margin-top: 5px;
}

.followEditionIcon i {
  color: white;
  position: relative;
  cursor: pointer;
  font-size: 23px;
  margin-top: 16px;
  margin-left: 14px;
}
.subscribeToPublicationBtn button {
  background-color: #ee7f0c;
  width: 120px;
  height: 40px;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 14px;
  margin-right: 13px;
}

.TNSAboutMagazine-name {
  display: flex;
  flex-direction: column;
}

.TNSAboutMagazine-name p {
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.856);
}
.TNSAboutMagazine-name p:first-child {
  text-transform: uppercase;
}

.TNSAboutMagazine-socialEngagement {
  color: black;
}

.TNSAboutMagazine-socialEngagement span {
  margin-right: 10px;
}

.TNSAboutMagazine-socialEngagement i {
  margin-right: 4px;
  color: #ee7f0c;
}

.linehr-publlicPub {
  width: 100%;
  height: 10px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

.publicationPublicAbout {
  display: flex;
}

.shraePublication-shareSocials {
  display: flex;
}

.shraePublication-shareSocials div {
  margin-right: 8px;
}
.pubgiu-img-wrap {
  height: 90px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.pubgiu-img-wrap img {
  height: 100%;
  /* width: 100%; */
  border-radius: 3px;
}
.pubgiu-aboutTexxtQrap {
  margin-left: 15px;
}

.pubgiu-aboutTexxtQrap h5 {
  margin: 0;
  padding: 0;
  font-size: 17px;
}

.pubgiu-aboutTexxtQrap p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #002233;
  margin-bottom: 10px;
}

.copyToClipBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 630px) {
  .newspaperWrapBody {
    width: 180px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .paperImgHover {
    width: 180px;
    height: 250px;
  }
  .wraopNewpaperIMg {
    width: 180px;
    height: 250px;
  }

  .TNSAboutMagazine {
    width: 100%;
  }

  .TNSNewsPaperCover {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
}

@media (max-width: 425px) {
  .newspaperWrapBody {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 0;
    margin-right: 0;
  }
  .TNSNewsPaperCover {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .paperImgHover {
    width: 100%;
    height: 450px;
  }
  .wraopNewpaperIMg {
    width: 100%;
    height: 450px;
  }

  .TNSAboutMagazine {
    width: 100%;
  }
}

.sigle-book-col-two {
  margin-left: 20px;
}

.sigle-book-col-two .newspaperWrapBody {
  width: 150px;
  height: 227px;
  margin-right: 7px;
}
.sigle-book-col-two .newspaperWrapBody .wraopNewpaperIMg {
  width: 100% !important;
  height: 200px;
}

.sigle-book-col-two .subscribeToPublicationBtn button {
  width: 80px;
  height: 35px;
  font-size: 12px;
}

.paperImgHover {
  height: 100% !important;
  width: 100% !important;
}
.sPPW {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sigle-book-col-two {
  margin-left: 27px;
  width: 100%;
}

.singdfghjhyh .edition-type-home {
  margin-left: 2px !important;
  margin-bottom: 7px;
}
@media (max-width: 873px) {
  .single-book-section {
    flex-direction: column;
  }
  .sigle-book-col-one {
    margin-top: 1px;
  }
  .singdfghjhyh .edition-type-home {
    margin-bottom: 20px;
  }
  .sigle-book-col-two {
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  .sigle-book-col-two .newspaperWrapBody .wraopNewpaperIMg {
    width: 100%;
    height: 450px;
  }
  .sigle-book-col-two .newspaperWrapBody {
    width: 100%;
    height: 490px;
    margin-right: 7px;
  }

  .sigle-book-col-one {
    width: 100%;
  }
}

.product-image {
  float: left;
  width: 20%;
}
.product-details {
  float: left;
  width: 37%;
}
.product-price {
  float: left;
  width: 12%;
}
.product-quantity {
  float: left;
  width: 10%;
}
.product-removal {
  display: flex;
  width: 9%;
}
.product-line-price {
  text-align: left;
}
/* This is used as the traditional .clearfix class */
.group:before,
.shopping-cart:before,
.column-labels:before,
.product:before,
.totals-item:before,
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  content: "";
  display: table;
}
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  clear: both;
}
.group,
.shopping-cart,
.column-labels,
.product,
.totals-item {
  zoom: 1;
}
/* Apply clearfix in a few places */
/* Apply dollar signs */
.product .product-price:before,
.product .product-line-price:before,
.totals-value:before {
  content: "$";
}

.shopping-cart {
  margin-top: 30px;
  background-color: #f0f0f0;
  width: 60%;
  padding: 20px;
  border-radius: 5px;
}
/* Column headers */
.column-labels label {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.column-labels .product-image,
.column-labels .product-details,
.column-labels .product-removal {
  text-indent: -9999px;
}
/* Product entries */
.product {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.product .product-image {
  text-align: center;
}
.product .product-image img {
  width: 100px;
}
.product .product-details .product-title {
  margin-right: 20px;
}
.product .product-details .product-description {
  margin: 5px 20px 5px 0;
  line-height: 1.4em;
}
.product .product-quantity input {
  width: 40px;
}
.product .remove-product {
  border: 0;
  padding: 4px 8px;
  background-color: #c66;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}

.product .subscribe-product {
  border: 0;
  padding: 4px 8px;
  background-color: #ee7f0d;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 5px;
}

.product .remove-product:hover {
  background-color: #a44;
}
/* Totals section */
.totals .totals-item {
  float: right;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
}
.totals .totals-item label {
  float: left;
  clear: both;
  width: 79%;
  text-align: right;
}
.totals .totals-item .totals-value {
  float: right;
  width: 21%;
  text-align: right;
}
.totals .totals-item-total {
}
.checkout {
  float: right;
  border: 0;
  margin-top: 20px;
  padding: 6px 25px;
  background-color: #6b6;
  color: #fff;
  font-size: 25px;
  border-radius: 3px;
}
.checkout:hover {
  background-color: #494;
}
/* Make adjustments for tablet */
@media screen and (max-width: 650px) {
  .shopping-cart {
    margin: 0;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  .column-labels {
    display: none;
  }
  .product-image {
    float: right;
    width: auto;
  }
  .product-image img {
    margin: 0 0 10px 10px;
  }
  .product-details {
    float: none;
    margin-bottom: 10px;
    width: auto;
  }
  .product-price {
    clear: both;
    width: 70px;
  }
  .product-quantity {
    width: 100px;
  }
  .product-quantity input {
    margin-left: 20px;
  }
  .product-quantity:before {
    content: "x";
  }
  .product-removal {
    width: auto;
  }
  .product-line-price {
    /* float: right;
    width: 70px; */
  }
}
/* Make more adjustments for phone */
@media screen and (max-width: 350px) {
  .product-removal {
    float: right;
  }
  .product-line-price {
    /* float: right;
    clear: left;
    width: auto;
    margin-top: 10px; */
  }
  .product .product-line-price:before {
    content: "Item Total: $";
  }
  .totals .totals-item label {
    width: 60%;
  }
  .totals .totals-item .totals-value {
    width: 40%;
  }
}
/* 
.product-line-price {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
} */

.sub-type-cart {
  margin-top: 10px;
}

.inner-modal-wrap {
  width: 520px;
}

.cart-modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.13);
  background-color: white;
  margin: 0;
  padding: 0;
  padding: 5px 5px;
}
.cart-modal-header .btn {
  padding: 5px 11px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: none;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  margin-left: 10px;
}

.cart-modal-header .btn:focus {
  border: none !important;
}
.center-product-cartHeader {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-product-cartHeader span {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  font-size: 19px;
  line-height: 46px;
  text-shadow: rgba(255, 255, 255, 0.6) 0 1px 0;
  letter-spacing: -0.01em;
  white-space: nowrap;
}

.product-details-modal {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background-clip: padding-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: -9px;
}

.cart-productmodal .p-titl {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}
.p-img-se {
  display: flex;
  align-items: center;
}
.p-img-se img {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 9px 15px -5px rgba(0, 0, 0, 0.3);
  width: 90px;
  height: 70px;
}

.modal-about-p {
  margin-left: 8px;
}
.modal-about-p h1 {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.modal-about-p p {
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  color: #757575;
  font-size: 13px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0 !important;
}
.recommendedSub {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  top: -2px;
}
.recommendedSub p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
}
.button-subscribe-ed-modal button {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.527);
  border-color: transparent;

  font-size: 14px;
  width: 86px;
  border-radius: 5px;
  height: 35px;
}
.fyttt52h {
  font-size: 12px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.fyttt52h div button {
  font-size: 12px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: #ee7f0d !important;
}

@media (max-width: 600px) {
  .product-details-modal {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .recomdPubthgdt {
    display: none !important;
  }
  .recommendedSub {
    display: none !important;
  }
  .p-img-se img {
    width: 117px;
    height: 97px;
  }
  .button-subscribe-ed-modal {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .modal-about-p p {
    white-space: wrap !important;
  }
  .p-img-se {
    align-items: flex-start;
  }
}

@media (min-width: 600px) {
  .hdOnminSUBBTN {
    display: none !important;
  }
}

.user-profile-layout {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.user-profile-card-one {
  width: 18%;
  height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 50px;
}

.user-profile-card-two {
  width: 65%;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 5px;
}

.user-avatar-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.user-avatar-circle img {
  width: 95%;
  height: 95%;
  border-radius: 50%;
}

.username-profile {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.username-profile span {
  color: #6e7191;
}

.username-profile span:first-child {
  font-size: 15px;
  text-align: center;
}

.username-profile span:last-child {
  font-size: 13px;
  text-align: center;
}

.profile-pic-chaageImage {
  width: 130px;
  height: 40px;
  margin-top: 10px;
}

.profile-pic-chaageImage input {
  width: 130px;
  height: 40px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.profile-pic-chaageImage button {
  width: 130px;
  height: 40px;
  background-color: #ee7f0d;
  font-size: 12px;
  border-radius: 4px;
  color: white;
}

.user-profile-card-two {
  display: flex;
}

.user-profile-card-two-inner-two {
  width: 1px;
  height: 510px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  margin-top: 50px;
}

.userProfile-input-header {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: #002233;
}

.profileinput-box p {
  margin: 0;
  padding: 0;
  position: relative;
  top: 24px;
  color: #a0a3bd;
  font-size: 13px;
  padding-left: 7px;
  z-index: 10;
  z-index: 1;
}

.move-label-up-profile {
  position: relative !important;
  top: 2px !important;
  color: orange !important;
  transition: all 200ms ease-in;
  z-index: 3 !important;
  margin-left: -7px !important;
}

.profileinput-box input {
  opacity: 1;
  background: #eff0f759;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 45px;
  position: relative;
  top: -7px;
  z-index: 2;
  padding-left: 4px;
  font-size: 13px;
  color: #131314d3;
  padding-left: 10px;
}

.profileinput-box .PhoneInput {
  opacity: 1;
  background: #eff0f759 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-left: 10px !important;
  width: 350px !important;
  height: 45px !important;
  position: relative;
  top: -7px;
  z-index: 2;
  padding-left: 4px;
  font-size: 13px;
  color: #131314d3;
  padding-left: 10px;
}

.profileinput-box .PhoneInput .PhoneInputInput {
  border: none !important;
  position: relative;
  top: 1px;
}

.profileinput-box textarea {
  opacity: 1;
  background: #eff0f759;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 115px;
  position: relative;
  top: -7px;
  padding-left: 4px;
  z-index: 2;
  outline: none;
  padding-left: 10px;
  padding-top: 12px;
  font-size: 13px;
  color: #131314d3;
}

.user-profile-card-two-inner-one {
  padding: 10px 20px 20px 15px;
}

.appErrorProfil {
  margin-top: -8px !important;
  width: 340px;
  margin-bottom: 10px;
  font-size: 9px;
}

.user-profile-card-two-inner-three {
  margin-left: 25px;
  margin-top: 10px;
}
.social-handle-profile {
  margin-top: 20px;
}
.socila-m-profile-inner img {
  width: 5%;
}
.socila-m-profile-inner input {
  border-color: red;
  width: 75%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25) !important;
  margin-left: 16px;
  position: relative;
  top: -7px;
  font-size: 11px;
  margin-bottom: 19px;
  padding-bottom: 7px;
}

.profile-newsletter-box-inner {
  display: flex;
  width: 83% !important;
  font-size: 15px !important;
  margin-bottom: 10px;
  background: #eff0f6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;

  padding: 5px;
}

.profile-newsletter-box-inner span {
  width: 90%;
  font-size: 13px !important;
  color: #a0a3bd;
}
.inner-review {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: white;
  padding: 20px 14px;
  border-radius: 5px;
}
.review-img img {
  width: 100px;
  height: 100px;
}

.review-text {
  margin-left: 0;
  width: calc(100% - 112px);
}

.review-text p {
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  overflow: hidden;
  text-overflow: ellipsis;
}
.review-actions {
  margin: 0;
  padding: 0;
}

.review-actions img {
  margin-right: 20px;
  margin-top: 0;
}

.reviews-wrap {
  border-radius: 5px;
  padding: 15px;
  width: 90%;
  display: flex;
  justify-self: center;
  align-self: center;
  margin: auto;
  flex-direction: column;
}

.subscription-wrap {
  display: flex;
  justify-content: flex-start;
}

.col-one-subscription {
  background-image: url(/static/media/pub.727181c7.png);
  width: 25%;
  height: 490px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 60px;
  flex-direction: column;
}

.col-one-subscription p {
  color: white;
  text-align: center;
}

.col-one-subscription button {
  color: white;
  background-color: #ee7f0d;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  margin-top: -7px;
}
.col-two-subscription {
  margin-left: 20px;
  width: 830px;
}

.sub-img-wrap {
  width: 126px;
  height: 120px;
  border-right: 1.4px solid rgba(0, 0, 0, 0.1);
  padding-right: 7px;

  margin-bottom: 20px;
}

.sub-button-wrap {
  border-left: 1.4px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-left: 15px;
  justify-content: center;
  padding-left: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.sub-button-wrap button {
  color: white;
  background-color: #ee7f0d;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 13px;
}

.sub-button-wrap button:last-child {
  background: #f7f7fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: #002333;
}

.sub-img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.subs-wrap-inner {
  display: flex;
  justify-content: center;
}

.sub-about-wrap {
  margin-left: 15px;
}

.hr-sub {
  width: 100%;
  height: 1px;
  border: 1px dashed rgba(0, 0, 0, 0.25);

  margin-top: 16px;
  margin-bottom: 16px;
}
.sub-tit {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  color: #000000;
}

.sub-type {
  font-size: 12px;
  letter-spacing: 0.75px;
  text-transform: capitalize;

  color: #6e6e6e;
}

.sub-next {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.sub-warn {
  font-size: 12px;
  letter-spacing: 0.75px;
  color: #000000;
  font-style: italic;
}

.subs-wrap {
  background-color: white;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 19px;
  padding-bottom: 0px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.subscription-header {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}

.following-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
}

.following-cards > div {
  margin-left: 10px;
}
.following-img-wrap img {
  width: 100%;
  height: 155px;
}

.following-profile {
  margin-left: 57px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.reviewjk-profile {
  margin-left: 0px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.continue-reading {
  margin-left: 37px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.following-uname {
  color: #6e7191;
  font-weight: 600;
  font-style: 20px;
}

.fol-fol {
  font-size: 13px;
  color: #6e7191;
}

.following-wrap {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
  width: 100vw;
  grid-gap: 10px;
  padding-right: 20px;
}

.subAccountSub button {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
}

.editProfilebtn {
  padding: 10px;
  background-color: #ee7f0d;
  border-radius: 5px;
  margin-top: 20px;
  color: white;
  font-size: 12px;
}

.changeUserPassword {
  width: 100%;
  padding: 20px;
  padding-top: 40px;
}

.changePassinputWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.changePassinputWrap label {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: black;
  padding-bottom: 5px;
}

.changePassinputWrap input {
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  font-size: 13px;
  padding-left: 10px;
}

.updateUserPaswordTitle {
  text-align: center;
}

.updateUserPaswordTitle h1 {
  font-size: 18px;
  margin-bottom: 40px;
}

.subComment-wrap {
  background-color: rgba(128, 128, 128, 0.063);
  padding: 10px;
}

.subAccountInputWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}
.subAccForm {
  padding-bottom: 40px;
}

.subAccountInputtaglabel {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #000000;
  font-size: 14px;
}

.subAccountInputWraphgf .subAccountInputtag {
  border: 1px solid rgba(128, 128, 128, 0.062);
  width: 100%;
  border-radius: 4px;
  color: black;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: rgba(128, 128, 128, 0.062);
  font-size: 12px;
  margin-top: 5px;
  padding-left: 4px;
}

.subAccountInputWraphgf .subAccountInputtag option {
  font-size: 12px !important;
}

.react-select .css-1s2u09g-control {
  border: none !important;
  background-color: rgba(128, 128, 128, 0.062);
  font-size: 12px !important;
}

.react-select .css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
  background-color: rgba(128, 128, 128, 0.062);
}

.react-select .css-1pahdxg-control {
  border: none !important;
}

.react-select .css-2613qy-menu {
  padding-bottom: 20px !important;
}

.handleSubMe {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
}

.handleSubMe button {
  background-color: #ee7f0d;
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

@media (max-width: 1245px) {
  .user-profile-card-two {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .profileinput-box input,
  .profileinput-box textarea,
  .profile-newsletter-box-inner,
  .socila-m-profile-inner input {
    width: 90% !important;
  }
}

@media (max-width: 1083px) {
  .user-profile-layout {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .user-profile-card-two {
    margin-top: 20px;
  }
  .user-profile-card-one {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
}

@media (max-width: 803px) {
  .user-profile-card-two-inner-two {
    display: none !important;
  }
  .user-profile-card-two-inner-one {
    padding-right: 0;
  }
  .user-profile-card-two-inner-three {
    margin-left: 0;
  }
}
@media (max-width: 735px) {
  .user-profile-card-two {
    flex-direction: column;
    width: 100%;
  }
  .user-profile-card-two-inner-one {
    padding: 20px;
  }
  .user-profile-card-two-inner-three {
    margin: 20px;
  }
  .profileinput-box input,
  .profileinput-box textarea,
  .profile-newsletter-box-inner {
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .following-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .following-cards {
    width: 95%;
  }
  .section-wrap {
    padding-left: 10px;
  }
  .following-img-wrap img {
    height: 363px;
  }
  .following-profile {
    text-align: center;
  }
}
@media (max-width: 707px) {
  .reviews-wrap {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .inner-review {
    width: 100%;
    /* padding: 0; */
  }

  .section-wrap {
    padding: 10px;
  }
}

@media (max-width: 420px) {
  .inner-review {
    width: 100%;
    flex-direction: column;
  }
  .review-img img {
    width: 100%;
    height: 300px;
  }
  .review-text {
    width: 100%;
    margin-top: 10px;
  }
  .reviewjk-profile {
    text-align: center;
  }
}

@media (max-width: 1094px) {
  .col-one-subscription {
    display: none !important;
  }
  .sub-button-wrap button {
    white-space: nowrap;
    font-size: 10px;
  }
}

@media (max-width: 740px) {
  .subs-wrap-inner {
    padding-bottom: 20px;
  }
}

@media (max-width: 590px) {
  .subs-wrap-inner {
    flex-direction: column;
  }
  .sub-img-wrap {
    width: 100%;
    height: 400px;
  }
  .col-two-subscription {
    margin-left: 0;
  }
  .sub-about-wrap {
    margin-left: 15px;
    margin-bottom: 17px;
  }
}

#pdf-container div {
  height: 900px;
  width: 100%;
  background-color: rgba(64, 64, 64, 1);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 50px;
  padding-top: 80px;
}

#pdf-container canvas {
  width: 100% !important;
}

.pdfNavControl {
  width: 100%;
  height: 35px;
  background-color: #ee7f0d;
  position: fixed;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconConNav img {
  width: 15px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pdfSelPage {
  width: 90px;
  border-radius: 3px;
  background-color: white;
  font-size: 13px;
  padding: 5px;
  padding-top: 1px;
  font-size: 12px;
  text-align: center;
}

.iconConNav {
  display: flex;
  align-items: center;
}

.iconConNav span i {
  margin-right: 15px;
  margin-left: 12px;
  color: white;
  cursor: pointer;
}
/* .pdfbtnContainer {
  position: relative;
} */
/* .nextbtnPdf {
  position: absolute;
  right: 0;
  top: 50%;
  background-color: red;
} */
.commentLikeBoxWrap {
}
.commentSectionBtn {
  position: fixed;
  z-index: 20;
  right: -15px;
  width: 100px;
  height: 120px;
  bottom: 149px;
  display: flex;
  flex-direction: column;
}

.commentSectionBtn img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  cursor: pointer;
}

.commentBoxSection {
  width: 400px;
  height: 450px;
  background-color: white;
  position: fixed;
  z-index: 25;
  top: 125px;
  right: 2px;
  border-radius: 5px 5px 5px 5px;
  filter: drop-shadow(2px 3px 4px grey);
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.37);
  color: white;
  font-size: 18px;
}

.canceCommentBox {
  color: red;
  font-size: 26px;
  position: relative;
  bottom: 5px;
}

.commentBoxUserComment {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  background-color: rgba(128, 128, 128, 0.37);
  position: fixed;
  bottom: 0;
}

.commentBoxUserComment textarea {
  padding: 5px;
  width: 80%;
  height: 50px;
  border-radius: 3px;
  border: none;
  font-size: 11px;
  outline: none;
}

.commentBoxUserComment textarea:focus {
  border: none;
}

.commentBoxUserComment button {
  background-color: orange;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 3px;
  height: 40px;
}

.commentBody {
  height: calc(100% - 117px);
  width: 100%;
  background-color: white;
  position: absolute;
  top: 47px;
  padding-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
}
.commentBody::-webkit-scrollbar {
  visibility: hidden;
}
.commentTextBox {
  min-width: 90%;
  background-color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 10px;

  border-bottom: 0.7px solid rgba(128, 128, 128, 0.452);
}

.commentTextBox {
  margin-bottom: 5px;
  margin-left: 10px;
}

.commenttextdata {
  font-size: 11px;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  margin-top: -5px;
  padding-left: 45px;
  padding-left: 48px;
  color: black;
  position: relative;
  top: -15px;
}
.commenttextdataSub {
  font-size: 45px;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.commentUserDetail span {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -8px;
  padding-left: 6px;
}

.commentActions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: -7px;
}

.commentActions span {
  font-size: 10px;
  font-weight: 600;
  margin-right: 5px;
  cursor: pointer;
}

.replyInputBoxWrap {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.replyInputBoxWrap input {
  height: 40px;
  width: 80%;
  border-radius: 4px;
  font-size: 11px;
  background: #eeeeee;
}

.replyInputBoxWrap button {
  height: 40px;
  padding: 0 10px 0 10px;
  border-radius: 4px 4px 4px 4px;
  font-size: 11px;
  color: black;
}

.subComment-wrap {
  border-top: 0.7px solid rgba(128, 128, 128, 0.295);
  padding-bottom: 15px;
  padding-top: 10px;
}

.commentCoutEdition {
  position: relative;
  background-color: #ee7f0d;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: 7px;
  font-size: 11px;
}

@media (max-width: 600px) {
  .commentSectionBtn img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .commentCoutEdition {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 455px) {
  .commentBoxSection {
    width: 90vw;
    top: 140px;
  }
}

@media (max-width: 320px) {
  .commentBoxSection {
    width: 90vw;
    top: 140px;
    height: 410px;
  }
}

.rpv-default-layout-toolbar {
  position: fixed;
  right: 0;
  top: 75px;
  z-index: 12;
  /* width: calc(100% - 250px); */
}

.pdf-section-cover {
  display: flex;
  position: relative;
  background-color: #e5e5e5;
}

.pdf-section-cover .digitalEdition-Sidebar {
  width: 250px;
  background-color: #eeeeee;
  position: fixed;
  height: 100vh;
  border-right: 0.8px solid rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 20px 10px 30px 20px;
  z-index: 567890;
  overflow: hidden;
  overflow-y: scroll;
}
.digitalEditWrap {
  width: 100%;
}

.digital-edition-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.digital-edition-header-likeContain {
  margin-right: auto;
}

.digital-edition-header-likeContain i {
  font-size: 15px;
  color: #ee7f0d;
}
.digital-edition-header-likeContain span {
  font-size: 12px;
  margin-left: 8px;
}
.fa-share-alt {
  color: rgba(0, 0, 0, 0.712);
}

.image-digital-wrapperkgu {
  width: 100%;
  height: 270px;
  background-color: grey;
  margin-top: 14px;
  border-radius: 3px;
}

.image-digital-wrapperkgu img {
  width: 99%;
  height: 99%;
}

.editHeaderNmae-digital h1 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.editHeaderNmae-digital p {
  font-size: 13px;

  text-align: center;
  margin-top: 0;
  color: #002233;
  margin-top: 3px;
}
.pageViewSetter div span {
  margin-left: 10px;
  color: #000000;
  font-size: 14px;
}

.pageview,
.textViewS {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.pageViewSetter .pageview {
  margin-bottom: 15px;
}
.editHeaderNmae-digital h1::before {
  display: block;
  content: "";
  border: 0.8px dashed rgba(128, 128, 128, 0.219);
  margin-top: 19px;
  margin-bottom: 11px;
}
.pdf-section-cover .mainPdfCover {
  width: 100%;
  position: relative;
}

.rpv-core-progress-bar-inner {
  background-color: #ee7f0d !important;
}

.rpv-default-layout-sidebar-tabs {
  display: flex;
  height: 100%;
  width: 100%;
  /* position: fixed; */
  height: 100vh;
  top: 122px;
  z-index: 10;
}

/* .rpv-toolbar-item {
  display: none !important;
} */

@media (max-width: 575px) {
  .inner-modal-wrap {
    width: 90vw !important;
  }
}

.rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 350px !important;
}

@media (max-width: 700px) {
  .rpv-thumbnail-list {
    width: 100px !important;
  }

  .rpv-default-layout-sidebar-headers {
    display: none !important;
  }
}

.textview-footer {
  background-color: #eaeaea;
  width: calc(100vw - 200px);
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 200px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.tabcontrollerFGoooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  height: 37px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0;
}

.icontextviewwrapf {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.tectViewCol1 {
  width: 10%;
}

.setPdfFotterCol {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}

.showpagesLinkText {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-x: scroll;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
}

.showpagesLinkText ol {
  display: flex;
  height: 100%;
  margin-bottom: 0;
  list-style-type: none;
}

.showpagesLinkText ol li {
  white-space: nowrap;
  margin-right: 40px;
  font-size: 15px;
  padding: 10px;
  padding-top: 15px;
  color: #002233;
  height: 100%;
  cursor: pointer;
}

.showpagesLinkText ol li .Tnumbers {
  margin-right: 5px;
}

.activeFooterTextViewTab {
  background-color: #ee7f0d;
  color: white;
  height: 110%;
  transition: all 300ms ease;
}
.arrowLeftF {
  cursor: pointer;
  margin-left: 20px;
}
.arrowRightF {
  margin-left: 50px;
  cursor: pointer;
}
.arrowRightF div svg {
  transform: rotate(181deg);
}

.tohfghHarmburger {
  position: relative;
}

.tohfghHarmburger .harmdfuys {
  position: fixed;
  left: 1px;
  z-index: 456789;
  top: 76px;
}
.showtextViewMobileClick {
  position: fixed;
  right: 8px;
  z-index: 456789;
  bottom: 20px;
  cursor: pointer;
}

.textViewMobileListContainerMain {
  background-color: white;
  width: 100%;
  padding: 12px 20px 20px 20px;
  margin-top: -12px;
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.textViewMobileListContainerMain .fawsomeIconsArticles i {
  margin-left: 13px;
}

.textViewMobileListContainerMain .fawsomeIconsArticles .smThip {
  color: #ee7f0d;
  font-size: 16px;
  cursor: pointer;
  /* -webkit-animation: movementOnArticleIcons 700ms ease infinite;
  animation: movementOnArticleIcons 700ms ease infinite; */
}

/* @-webkit-keyframes movementOnArticleIcons {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(1px);
  }
} */

@keyframes movementOnArticleIcons {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@media (min-width: 1089px) {
  .tohfghHarmburger,
  .showtextViewMobileClick {
    display: none;
  }
}

@media (max-width: 1089px) {
  .hideViwwPaperSidebarOnMobile {
    display: none !important;
  }
  .commentSectionBtn {
    display: none !important;
  }
  .tohfghHarmburgerButtonLong {
    display: none;
  }
  .pdf-section-cover .mainPdfCover {
    width: 100%;
    position: relative;
    left: 0;
  }

  .rpv-default-layout-toolbar {
    position: fixed;
    left: 43px;
    top: 75px;
    z-index: 12;
    width: 100% !important;
  }
}

.sk-editor-render-style div iframe {
  margin-bottom: 20px;
  width: 100% !important;
  height: 450px !important;
}

@media (max-width: 591px) {
  .sk-editor-render-style div img {
    float: none !important;
    width: 100% !important;
    height: auto !important;
    padding-left: 0 !important;
  }
}

.showtextViewMobile {
  position: relative;
}

.textViewMobileListContainerMain ol {
  list-style-type: none;
}

.textViewMobileListContainerMain ol li {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 13px;
}

.textViewMobileListContainerMain ol li span {
  padding-right: 10px;
  /* font-size: 1px; */
}

.textViewMobileListContainerMainheaderTit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 12px;
  border-bottom: 1px dashed rgba(128, 128, 128, 0.438);
}

.textViewMobileListContainerMainheaderTit span {
  font-size: 15px;
}

.textViewMobileListContainerMainHide {
  display: none;
}

@media (max-width: 1089px) {
  .pdfViewSizer {
    width: 100vw;
    left: 0;
  }
  .rpv-default-layout-container {
    margin-top: 0px;
  }
}

@media (min-width: 1089px) {
  .pdfViewSizer {
    position: relative;
    /* left: 45px; */
    top: 65px;
    /* width: calc(100% - 100px); */
  }
  .rpv-default-layout-container {
    margin-top: -70px;
  }
}

.rpv-default-layout-sidebar-headers {
  position: fixed;
  z-index: 10000;
  height: 100vh;
  margin-top: 54px;
}

.blobs-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 65px;
  width: 65px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgb(238, 127, 13);
  box-shadow: 0 0 0 0 rgb(238, 127, 13);
  animation: pulse-red 1s infinite;
  position: relative;
  right: 30px;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.textViewContainer-grid {
  /* display: grid;
  grid-template-areas:
    "col1 col2 col2"
    "col1 col2 col2"
    "col1 col3 col4"; */
  /* height: 900px; */
  /* grid-template-rows: 1fr 1fr 1fr; */
  /* grid-template-columns: 350px 1fr 1fr; */
  /* grid-gap: 15px; */
  max-width: 1040px;
  margin: auto;
  /* background-color: pink; */
}
.textViewWithImage {
  width: 80%;
  position: relative;
  left: 230px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #e5e5e5;
  /* margin-top: 30px; */
  margin-left: 2.5%;
  box-shadow: 0px 0px 8px rgba(211, 211, 211, 0.371);
  border-radius: 5px;
  position: relative;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  overflow-y: scroll;
}
@media (max-width: 1089px) {
  .textViewWithImage {
    width: 97%;
    left: 0;
  }
}
.tviewgrid1 {
  grid-area: col1;
  height: 800px;
  /* background-color: orange; */
}

.tviewgrid2Img {
  grid-area: col2;
}

.tviewgrid2Img img {
  width: 100%;
  padding-right: 0;
  border-radius: 4px;
}

.tviewgrid3 {
  grid-area: col3;
  height: 350px;
  width: 350px;
  padding: 5px;
}

.tviewgrid4 {
  grid-area: col4;
  height: 350px;
  width: 350px;
}

.postTitleST {
  max-width: 1040px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 22px;
}

.postTitleST h1 {
  font-family: var(--textViewPdfFont) !important;
}

.post-des {
  margin-top: 0;
  font-weight: 400;
  color: #000000;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: -15px;
}

.post-autor {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
}

.postTags {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 15px;
}

.sk-editor-render-style * {
  padding: 0;
  margin: 0;
}

.sk-editor-render-style p {
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  line-height: 35px;
  margin-bottom: 4px !important;
}

.sk-editor-render-style iframe {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 450px;
}

.sk-editor-render-style img {
  width: 45%;
  /* height: 700px; */
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* padding-left: 20px; */
  float: right;
  transition: all 400ms ease;
}

.sk-editor-render-style img:hover {
  filter: grayscale(50%);
}

.sk-editor-render-style h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-size: 19px !important;
  line-height: 18px !important;
  color: #000000;
  line-height: 28px !important;
  margin-bottom: 5px;
  margin-top: 25px !important;
  font-family: var(--textViewPdfFont) !important;
}

.sk-editor-render-style iframe {
  width: 100% !important;
  border-radius: 5px;
}

.sk-editor-render-style p:empty {
  display: none !important;
}

.sk-editor-render-style a {
  color: #ee7f0d !important;
  /* text-decoration: underline !important; */
}
.sk-editor-render-style {
  padding-bottom: 70px;
}
.textWithImage-remainder {
  display: grid;
  grid-template-columns: 330px 330px 330px;
  grid-gap: 10px;
  max-width: 1040px;
  margin: auto;
  margin-top: 20px;
}

.allUsedTags {
  margin-right: 5px;
}

/* .app-container {
  background-color: red;
} */

.textViewCoverImage {
  width: 100%;
  /* height: 600px; */
  overflow: hidden;
  /* border: 2px solid #ee7e0d3a; */
  display: flex;
  height: 400px;
  border-radius: 5px;
  /* padding: 20px; */
  overflow: hidden;
}

.textViewCoverImage img {
  /* width: 100%; */
  border-radius: 5px;
  /* height: 100%; */
  transition: all 500ms ease;
  cursor: pointer;
  float: right;
}

.textViewCoverImage img:hover {
  /* transform: scale(1.2); */
}

.userGiftingMessage {
  height: 45px;
  width: 100px;
  position: fixed;
  right: -26px;
  z-index: 999;
  bottom: 103px;
}

.userGiftingMessage img {
  width: 43px;
  cursor: pointer;
  margin-left: 10.5px;
  margin-top: 11px;
}

.userGiftingMessage-modal {
  height: 340px;
  width: 300px;
  background-color: white;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 70px;
  padding: 20px;
  border-radius: 4px;

  filter: drop-shadow(4px 5px 5px rgba(128, 128, 128, 0.678));
  overflow-y: scroll;
}

.userGiftingMessage-modal::-webkit-scrollbar {
  opacity: 0;
}
.gift-user-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(238, 127, 13, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.gift-user-logo img {
  width: 40px;
}

.closeGiftModalCss {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.closeGiftModalCss img {
  width: 40px;
  background: rgba(0, 0, 0, 0.08);
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.gifting-msg-body {
  padding-top: 22px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.gifting-msg-body img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
}

.pager {
  list-style-type: none;
}
.pdf-viewer-wrap {
  margin-top: 40px;
  background-color: rgba(128, 128, 128, 0.76);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
  height: 700px;
  width: 100%;
}

.previous-pdf-btn {
  position: absolute;
  left: 10%;
}

.next-pdf-btn {
  position: absolute;
  right: 10%;
}

.book__page {
  position: relative;
  width: 50%;
  height: 100%;
  display: grid;
  transform: rotateY(0deg);
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: 0% 0%;
  background-color: red;
  background-image: linear-gradient(
    90deg,
    rgba(227, 227, 227, 1) 0%,
    rgba(247, 247, 247, 0) 18%
  );
}
.book__page:nth-of-type(1) {
  background-image: linear-gradient(
    0deg,
    rgba(227, 227, 227, 1) 0%,
    rgba(247, 247, 247, 0) 18%
  );
}
.book .book__page-front {
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotateY(10deg);
}

.book .book__page {
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotateY(-120deg);
}

.changeofNameBanner {
  width: 100vw;
  background-image: url(/static/media/changOfNameBanner.64875b74.png);
  background-size: cover;
  height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px 10px 80px;
}
.chNameTextMain h1 {
  color: white;
  font-size: 40px;
  font-weight: 600;
  color: #eaf6fc;
}

.chNameTextMain p {
  font-size: 18px;
  color: #eaf6fc;
  margin-top: 20px;
}

.drpSelOp select {
  background: #eff0f7;
  border-radius: 7px;
  width: 350px;
  height: 50px;
  padding: 10px;
}

.newspaperListChangeOfname {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
  /* min-width: 5600px; */
  width: 80vw;
  margin-top: 50px;
  grid-gap: 14px;
}

@media (max-width: 484px) {
  .newspaperListChangeOfname {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.newspaperListChangeOfname_child {
  width: 200px;
  height: 247px;
  background: #ffffff;
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 12px;
  overflow: hidden;
  padding: 20px;
  transition: all 400ms ease;
}

.newspaperListChangeOfname_child:hover {
  transform: translateY(-20px);
  background-color: rgba(128, 128, 128, 0.137);
  border: 1px solid rgba(238, 126, 13, 0.644);
  border-radius: 5px;
}

.newspaperListChangeOfname_child img {
  /* width: 90%; */
  height: 100px;
  /* margin-top: 30px; */
}

.changeOfNameTabWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.changeOfNameTabWrapIn {
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeOfNameTabWrapIn ul {
  list-style-type: none;
  display: flex;
  margin-top: 50px;
  grid-gap: 40px;
  gap: 40px;
}

.changeOfNameTabWrapIn ul li {
  /* margin-right: 47px; */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #d9dbe9;
}
/* 
.progress_tab_wrapper {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}

.progress_tab_wrapper .progress_tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.progress_tab_wrapper .progress_tab .progress_tab_number {
  width: 57px;
  height: 57px;
  border-radius: 100%;
  background: #ee7f0d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  font-weight: 500;
}

.progress_tab_wrapper .progress_tab .progress_tab_label {
  font-size: 14px;
  font-weight: 400;
  color: #ee7f0d;
  margin: 0;
} */

.tab_label {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #002233;
}

.trsjgfrlosa span:first-child {
  margin-right: 20px;
}

.ftrsjgfrlosaqpbt {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d9dbe9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 12px;
  color: white;
}

.inputChangeNameBox {
  width: 500px;
  margin-top: 20px;
}

.inputnwCh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.inputnwCh .mwhgeqinput {
  width: 48%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  font-size: 13px;
  color: #7c7c7c;
}

.inputnwCh .mwhgeqinputDiv {
  width: 48%;
  display: flex;
  flex-direction: column;
  /* height: 45px; */
  font-size: 14px;
}

.inputnwCh .mwhgeqinputDiv input {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  font-size: 13px;
  color: #7c7c7c;
}

.inputnwCh .mwhgeqinputDiv label {
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.inputnwCh textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  color: #7c7c7c;
}

.actionBtnCName {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBtnCName button {
  margin: 40px;
  width: 130px;
  height: 45px;
  border-radius: 4px;
  color: white;
  background-color: transparent;
}
.actionBtnCName button:first-child {
  border: 1px solid #ee7f0d;
  color: #ee7f0d;
}
.actionBtnCName button:last-child {
  background-color: #ee7f0d;
}
.inputnwChtab3 {
  display: flex;
}
.inputnwChtab3 .inputnwChtab3FakeFileI {
  width: 370px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 7px 0 0px 7px;
  cursor: pointer;
}

.inputnwChtab3label {
  position: absolute;
  left: 12px;
  font-size: 12px;
  top: 12px;
  cursor: pointer;
}
.inputnwChtab3 .mwhgeqinputTab3 {
  width: 370px;
  height: 40px;
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
  cursor: pointer;
}

.inputnwChtab3Button {
  border-radius: 0px 7px 7px 0px;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  background: #ee7f0d;
  color: white;
  font-size: 11px;
}

.inputChangeNameBoxtab3 textarea {
  width: 95%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 120px;
  font-size: 13px;
  padding: 12px;
}

.inner-modal-wrap-trvChN {
  width: 885px;
  background-color: #fff;
}

.previewChangeOfname1Wrap {
  padding: 30px 80px;
}

.changeOfNameNewNPreview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 60px;
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.confirm {
  font-size: 16px;
  color: #ff0000;
  font-weight: 400;
}

/* 
.changeOfNameNewNPreviewOne h2,
.changeOfNameNewNPreviewtwo h2 {
  font-size: 13px !important;
  font-weight: 600;
  margin: 0;
} */

.changeOfNameNewNPreviewOne p,
.changeOfNameNewNPreviewtwo p {
  font-size: 13px;
  color: #4e4b66;
  margin: 0;
}

.changeOfNameNewNPreviewOne .label,
.changeOfNameNewNPreviewtwo .label {
  font-size: "16px";
  font-weight: 700;
  color: "#000000";
}

.cNLine {
  width: 100%;
  /* height: 1px; */
  border: 0.5px dashed #ee7e0d77;
}
.dcCns {
  display: flex;
}
.dcCns div {
  width: 120px;
  height: 150px;
  background-color: #4e4b66;
  margin-right: 13px;
  box-shadow: 0px 6.71186px 16.7797px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  border-radius: 4px;
}

.cnameAboutPub {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.cnameAboutPub img {
  margin-right: 50px;
}

.spLogosCN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 70px;
}
.spLogosCN img {
  width: 160px;
}

.cnNoticeg {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cnNoticeg h1 {
  font-size: 17px;
  margin-bottom: 12px;
}

.cnNoticeg p {
  font-size: 14px;
  text-align: center;
  color: #6e7191;
}

.cnNoticeg p span {
  color: #ee7e0dc5;
}
@media (max-width: 1110px) {
  .changeofNameBanner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    height: 400px;
  }
  .drpSelOp {
    margin-top: 25px;
  }
  .chNameTextMain p {
    font-size: 15px;
  }

  .chNameTextMain h1 {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .drpSelOp {
    width: 100%;
  }
  .drpSelOp select {
    width: 90%;
    margin-top: 6px;
  }
}

@media (max-width: 611px) {
  .inner-modal-wrap-trvChN {
    width: 90vw;
  }

  .changeOfNameNewNPreview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    gap: 20px;
    margin-top: 60px;
  }

  .changeOfNameTabWrapIn {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .previewChangeOfname1Wrap {
    padding: 30px;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .confirm {
    font-size: 12px;
    color: #ff0000;
    font-weight: 400;
  }

  .cnameAboutPub {
    display: flex;
    flex-direction: column;
    align-items: start;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 10px;
  }
}

@media (max-width: 532px) {
  .changeOfNameTabWrapIn ul {
    justify-content: center;
  }
  .trsjgfrlosa {
    display: none;
  }
  .cangeONameHeasderRes {
    width: 90%;
    text-align: center;
  }

  .inputnwCh {
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .inputnwCh .mwhgeqinput {
    width: 90vw;
    margin-bottom: 16px;
  }
  .inputChangeNameBox {
    margin-top: 30px;
  }

  .inputnwChtab3 .inputnwChtab3FakeFileI {
    width: 55%;
    overflow: hidden;
  }

  .inputnwChtab3 .mwhgeqinputTab3 {
    width: 65%;
    overflow: hidden;
  }

  .inputnwChtab3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
  }

  .inputnwChtab3label {
    left: inherit;
    white-space: nowrap;
  }

  .actionBtnCName button:first-child {
    margin-right: 0;
  }
}

.pubDays3h .pubDays3hCh1::after {
  content: "|";
  margin-right: 5px;
  margin-left: 5px;
}

.pubDays3h .pubDays3hCh1:last-child::after {
  content: "";
}

.Applicationcontainer {
  width: 90%;
  padding: 50px;
  background-color: white;
}

.Applicationcontainer .ApplicationcontainerMain {
  border: 1px solid rgba(0, 0, 0, 0.226) !important;
  border-radius: 10px;
}

.Applicationcontainer .MuiTypography-h6 {
  font-size: 12px !important;
}

.MuiButtonBase-root div {
  font-size: 12px !important;
}

.tabsAppli ul {
  display: flex;
  list-style-type: none !important;
  position: relative;
  bottom: -30px;
  z-index: 1233;
  margin-left: 20px;
}

.tabsAppli ul li {
  margin-right: 40px;
  color: #8692a6;
  font-size: 14px;
  display: block !important;
  text-align: center;
  cursor: pointer;
}

.tabsAppli ul .activedjsehapp::after {
  content: "";
  display: block !important;
  width: 100%;
  height: 2px;
  background: #ee7f0d;
}

.upSelsf {
  text-transform: capitalize !important;
}

#archivePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin-top: 40px;
}
.archivePageContainer {
  width: 470px;
  padding-bottom: 70px;
}

.achiveHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.achiveHeader h1 {
  font-size: 15px;
}

.achiveHeader p {
  font-size: 13px;
}

.achiveHeader * {
  margin: 0;
  padding: 0;
}

.achiveHeader h1 {
  margin-bottom: 8px;
}
.achiveHeader p {
  color: #6e7191;
}
.achiveHeader p span {
  color: #ee7f0d;
  font-weight: 600;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
}
.inputWrapAchive {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inputWrapAchive .inputWrapAchiveMain {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 7px;
  font-size: 13px;
  padding: 10px;
  color: #4e4b66;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.submitAchive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 83%;
  margin-top: 20px;
}
.submitAchive span {
  color: #000000;
  font-size: 15px;
}

.submitAchive span span:first-child {
  color: #ee7f0d;
  font-weight: 600;
  margin-left: 5px;
}

.submitAchive button {
  background-color: #ee7f0d;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
}

.selctAchiveP {
  height: 100% !important;
  padding: 50px;
  position: relative;
  z-index: 20000;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100% !important;
  height: 100% !important;
}

.addMoreDates {
  display: flex;
  width: 170px;
  height: 45px;
  margin-top: 0px;
  background: rgba(238, 127, 13, 0.14);
  border-radius: 24px;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  color: #ee7f0d;
  margin-left: 30px;
  cursor: pointer;
}

.addMoreDates img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.rminputWrapPad {
  margin-bottom: 15px;
}

.mtdatexand {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: red;
  font-size: 12px;
  cursor: pointer;
  font-style: italic;
  /* margin-bottom: 20px; */
}

.redeem-card-container {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.redeem-card-container .left-col {
}

.redeem-card-container .right-col {
  padding: 24px;
}

.redeem-card-container .left-col .heading {
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}

.redeem-card-container .left-col .heading2 {
  font-size: 40px;
  font-weight: 700;
  color: #ee7f0e;
  margin-top: 30px;
}

.redeem-card-container .left-col .benefit {
  display: flex;
  align-items: start;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.redeem-card-container .left-col .benefit p {
  font-size: 25px;
  font-weight: 500;
}

.redeem-card-container .checkbox-icon {
  width: 50px;
}

.redeem-card-container .right-col .redeem-box {
  width: 100%;
  min-height: 300px;
  padding: 20px;
  box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.redeem-card-container .right-col .redeem-box .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.redeem-card-container .right-col .redeem-box .redeem-coupon-image {
  width: 100%;
}

.redeem-card-container .right-col .redeem-box .benefit2-container {
  margin-top: 20px;
}

.redeem-card-container .right-col .redeem-box .benefit2-container .benefit2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  grid-gap: 15px;
  gap: 15px;
}

.redeem-card-container .right-col .redeem-box .benefit2-container .benefit2 p {
  font-size: 20px;
  align-self: center;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.redeem-card-container
  .right-col
  .redeem-box
  .benefit2-container
  .benefit2
  .icon {
  width: 40px;
  height: 40px;
  border: 2px solid #ee7f0e;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redeem-card-container .right-col .redeem-box .code-input {
  height: 55px;
  border-radius: 8px;
  border: 1px;
  background: #eff0f6;
  width: 100%;
  border: 1px solid #bdbdbd70;
  padding: 20px;
}

.redeem-card-container .right-col .redeem-box .message {
  font-size: 12px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
}
.redeem-card-container .right-col .redeem-box button {
  background: #ee7f0e;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 700px) {
  .redeem-card-container {
    /* padding: 24px; */
    grid-template-columns: repeat(1, 1fr);
  }

  .redeem-card-container .left-col .heading {
    /* text-align: center; */
    font-size: 25px;
    line-height: 45px;
  }

  .redeem-card-container .left-col .heading2 {
    /* text-align: center; */
    font-size: 30px;
  }

  .redeem-card-container .checkbox-icon {
    width: 30px;
  }

  .redeem-card-container .left-col .benefit p {
    font-size: 18px;
    font-weight: 500;
  }

  .redeem-card-container .left-col .benefit {
    grid-gap: 10px;
    gap: 10px;
  }

  .redeem-card-container .right-col {
    padding: 12px;
  }
  .redeem-card-container .right-col .redeem-box {
  }
}

