#pdf-container div {
  height: 900px;
  width: 100%;
  background-color: rgba(64, 64, 64, 1);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 50px;
  padding-top: 80px;
}

#pdf-container canvas {
  width: 100% !important;
}

.pdfNavControl {
  width: 100%;
  height: 35px;
  background-color: #ee7f0d;
  position: fixed;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconConNav img {
  width: 15px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pdfSelPage {
  width: 90px;
  border-radius: 3px;
  background-color: white;
  font-size: 13px;
  padding: 5px;
  padding-top: 1px;
  font-size: 12px;
  text-align: center;
}

.iconConNav {
  display: flex;
  align-items: center;
}

.iconConNav span i {
  margin-right: 15px;
  margin-left: 12px;
  color: white;
  cursor: pointer;
}
/* .pdfbtnContainer {
  position: relative;
} */
/* .nextbtnPdf {
  position: absolute;
  right: 0;
  top: 50%;
  background-color: red;
} */
.commentLikeBoxWrap {
}
.commentSectionBtn {
  position: fixed;
  z-index: 20;
  right: -15px;
  width: 100px;
  height: 120px;
  bottom: 149px;
  display: flex;
  flex-direction: column;
}

.commentSectionBtn img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  cursor: pointer;
}

.commentBoxSection {
  width: 400px;
  height: 450px;
  background-color: white;
  position: fixed;
  z-index: 25;
  top: 125px;
  right: 2px;
  border-radius: 5px 5px 5px 5px;
  -webkit-filter: drop-shadow(2px 3px 4px grey);
  filter: drop-shadow(2px 3px 4px grey);
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.37);
  color: white;
  font-size: 18px;
}

.canceCommentBox {
  color: red;
  font-size: 26px;
  position: relative;
  bottom: 5px;
}

.commentBoxUserComment {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  background-color: rgba(128, 128, 128, 0.37);
  position: fixed;
  bottom: 0;
}

.commentBoxUserComment textarea {
  padding: 5px;
  width: 80%;
  height: 50px;
  border-radius: 3px;
  border: none;
  font-size: 11px;
  outline: none;
}

.commentBoxUserComment textarea:focus {
  border: none;
}

.commentBoxUserComment button {
  background-color: orange;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 3px;
  height: 40px;
}

.commentBody {
  height: calc(100% - 117px);
  width: 100%;
  background-color: white;
  position: absolute;
  top: 47px;
  padding-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
}
.commentBody::-webkit-scrollbar {
  visibility: hidden;
}
.commentTextBox {
  min-width: 90%;
  background-color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 10px;

  border-bottom: 0.7px solid rgba(128, 128, 128, 0.452);
}

.commentTextBox {
  margin-bottom: 5px;
  margin-left: 10px;
}

.commenttextdata {
  font-size: 11px;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  margin-top: -5px;
  padding-left: 45px;
  padding-left: 48px;
  color: black;
  position: relative;
  top: -15px;
}
.commenttextdataSub {
  font-size: 45px;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.commentUserDetail span {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -8px;
  padding-left: 6px;
}

.commentActions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: -7px;
}

.commentActions span {
  font-size: 10px;
  font-weight: 600;
  margin-right: 5px;
  cursor: pointer;
}

.replyInputBoxWrap {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.replyInputBoxWrap input {
  height: 40px;
  width: 80%;
  border-radius: 4px;
  font-size: 11px;
  background: #eeeeee;
}

.replyInputBoxWrap button {
  height: 40px;
  padding: 0 10px 0 10px;
  border-radius: 4px 4px 4px 4px;
  font-size: 11px;
  color: black;
}

.subComment-wrap {
  border-top: 0.7px solid rgba(128, 128, 128, 0.295);
  padding-bottom: 15px;
  padding-top: 10px;
}

.commentCoutEdition {
  position: relative;
  background-color: #ee7f0d;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: 7px;
  font-size: 11px;
}

@media (max-width: 600px) {
  .commentSectionBtn img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .commentCoutEdition {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 455px) {
  .commentBoxSection {
    width: 90vw;
    top: 140px;
  }
}

@media (max-width: 320px) {
  .commentBoxSection {
    width: 90vw;
    top: 140px;
    height: 410px;
  }
}

.rpv-default-layout-toolbar {
  position: fixed;
  right: 0;
  top: 75px;
  z-index: 12;
  /* width: calc(100% - 250px); */
}

.pdf-section-cover {
  display: flex;
  position: relative;
  background-color: #e5e5e5;
}

.pdf-section-cover .digitalEdition-Sidebar {
  width: 250px;
  background-color: #eeeeee;
  position: fixed;
  height: 100vh;
  border-right: 0.8px solid rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 20px 10px 30px 20px;
  z-index: 567890;
  overflow: hidden;
  overflow-y: scroll;
}
.digitalEditWrap {
  width: 100%;
}

.digital-edition-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.digital-edition-header-likeContain {
  margin-right: auto;
}

.digital-edition-header-likeContain i {
  font-size: 15px;
  color: #ee7f0d;
}
.digital-edition-header-likeContain span {
  font-size: 12px;
  margin-left: 8px;
}
.fa-share-alt {
  color: rgba(0, 0, 0, 0.712);
}

.image-digital-wrapperkgu {
  width: 100%;
  height: 270px;
  background-color: grey;
  margin-top: 14px;
  border-radius: 3px;
}

.image-digital-wrapperkgu img {
  width: 99%;
  height: 99%;
}

.editHeaderNmae-digital h1 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.editHeaderNmae-digital p {
  font-size: 13px;

  text-align: center;
  margin-top: 0;
  color: #002233;
  margin-top: 3px;
}
.pageViewSetter div span {
  margin-left: 10px;
  color: #000000;
  font-size: 14px;
}

.pageview,
.textViewS {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.pageViewSetter .pageview {
  margin-bottom: 15px;
}
.editHeaderNmae-digital h1::before {
  display: block;
  content: "";
  border: 0.8px dashed rgba(128, 128, 128, 0.219);
  margin-top: 19px;
  margin-bottom: 11px;
}
.pdf-section-cover .mainPdfCover {
  width: 100%;
  position: relative;
}

.rpv-core-progress-bar-inner {
  background-color: #ee7f0d !important;
}

.rpv-default-layout-sidebar-tabs {
  display: flex;
  height: 100%;
  width: 100%;
  /* position: fixed; */
  height: 100vh;
  top: 122px;
  z-index: 10;
}

/* .rpv-toolbar-item {
  display: none !important;
} */

@media (max-width: 575px) {
  .inner-modal-wrap {
    width: 90vw !important;
  }
}

.rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 350px !important;
}

@media (max-width: 700px) {
  .rpv-thumbnail-list {
    width: 100px !important;
  }

  .rpv-default-layout-sidebar-headers {
    display: none !important;
  }
}

.textview-footer {
  background-color: #eaeaea;
  width: calc(100vw - 200px);
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 200px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.tabcontrollerFGoooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  height: 37px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0;
}

.icontextviewwrapf {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.tectViewCol1 {
  width: 10%;
}

.setPdfFotterCol {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}

.showpagesLinkText {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-x: scroll;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
}

.showpagesLinkText ol {
  display: flex;
  height: 100%;
  margin-bottom: 0;
  list-style-type: none;
}

.showpagesLinkText ol li {
  white-space: nowrap;
  margin-right: 40px;
  font-size: 15px;
  padding: 10px;
  padding-top: 15px;
  color: #002233;
  height: 100%;
  cursor: pointer;
}

.showpagesLinkText ol li .Tnumbers {
  margin-right: 5px;
}

.activeFooterTextViewTab {
  background-color: #ee7f0d;
  color: white;
  height: 110%;
  transition: all 300ms ease;
}
.arrowLeftF {
  cursor: pointer;
  margin-left: 20px;
}
.arrowRightF {
  margin-left: 50px;
  cursor: pointer;
}
.arrowRightF div svg {
  transform: rotate(181deg);
}

.tohfghHarmburger {
  position: relative;
}

.tohfghHarmburger .harmdfuys {
  position: fixed;
  left: 1px;
  z-index: 456789;
  top: 76px;
}
.showtextViewMobileClick {
  position: fixed;
  right: 8px;
  z-index: 456789;
  bottom: 20px;
  cursor: pointer;
}

.textViewMobileListContainerMain {
  background-color: white;
  width: 100%;
  padding: 12px 20px 20px 20px;
  margin-top: -12px;
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.textViewMobileListContainerMain .fawsomeIconsArticles i {
  margin-left: 13px;
}

.textViewMobileListContainerMain .fawsomeIconsArticles .smThip {
  color: #ee7f0d;
  font-size: 16px;
  cursor: pointer;
  /* -webkit-animation: movementOnArticleIcons 700ms ease infinite;
  animation: movementOnArticleIcons 700ms ease infinite; */
}

/* @-webkit-keyframes movementOnArticleIcons {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(1px);
  }
} */

@-webkit-keyframes movementOnArticleIcons {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes movementOnArticleIcons {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@media (min-width: 1089px) {
  .tohfghHarmburger,
  .showtextViewMobileClick {
    display: none;
  }
}

@media (max-width: 1089px) {
  .hideViwwPaperSidebarOnMobile {
    display: none !important;
  }
  .commentSectionBtn {
    display: none !important;
  }
  .tohfghHarmburgerButtonLong {
    display: none;
  }
  .pdf-section-cover .mainPdfCover {
    width: 100%;
    position: relative;
    left: 0;
  }

  .rpv-default-layout-toolbar {
    position: fixed;
    left: 43px;
    top: 75px;
    z-index: 12;
    width: 100% !important;
  }
}

.sk-editor-render-style div iframe {
  margin-bottom: 20px;
  width: 100% !important;
  height: 450px !important;
}

@media (max-width: 591px) {
  .sk-editor-render-style div img {
    float: none !important;
    width: 100% !important;
    height: auto !important;
    padding-left: 0 !important;
  }
}

.showtextViewMobile {
  position: relative;
}

.textViewMobileListContainerMain ol {
  list-style-type: none;
}

.textViewMobileListContainerMain ol li {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 13px;
}

.textViewMobileListContainerMain ol li span {
  padding-right: 10px;
  /* font-size: 1px; */
}

.textViewMobileListContainerMainheaderTit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 12px;
  border-bottom: 1px dashed rgba(128, 128, 128, 0.438);
}

.textViewMobileListContainerMainheaderTit span {
  font-size: 15px;
}

.textViewMobileListContainerMainHide {
  display: none;
}

@media (max-width: 1089px) {
  .pdfViewSizer {
    width: 100vw;
    left: 0;
  }
  .rpv-default-layout-container {
    margin-top: 0px;
  }
}

@media (min-width: 1089px) {
  .pdfViewSizer {
    position: relative;
    /* left: 45px; */
    top: 65px;
    /* width: calc(100% - 100px); */
  }
  .rpv-default-layout-container {
    margin-top: -70px;
  }
}

.rpv-default-layout-sidebar-headers {
  position: fixed;
  z-index: 10000;
  height: 100vh;
  margin-top: 54px;
}

.blobs-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 65px;
  width: 65px;
  transform: scale(1);
  -webkit-animation: pulse-black 2s infinite;
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgb(238, 127, 13);
  box-shadow: 0 0 0 0 rgb(238, 127, 13);
  -webkit-animation: pulse-red 1s infinite;
  animation: pulse-red 1s infinite;
  position: relative;
  right: 30px;
}

@-webkit-keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
