.pager {
  list-style-type: none;
}
.pdf-viewer-wrap {
  margin-top: 40px;
  background-color: rgba(128, 128, 128, 0.76);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
  height: 700px;
  width: 100%;
}

.previous-pdf-btn {
  position: absolute;
  left: 10%;
}

.next-pdf-btn {
  position: absolute;
  right: 10%;
}

@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,600|Tulpen+One&display=swap");

.book__page {
  position: relative;
  width: 50%;
  height: 100%;
  display: grid;
  transform: rotateY(0deg);
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: 0% 0%;
  background-color: red;
  background-image: linear-gradient(
    90deg,
    rgba(227, 227, 227, 1) 0%,
    rgba(247, 247, 247, 0) 18%
  );
}
.book__page:nth-of-type(1) {
  background-image: linear-gradient(
    0deg,
    rgba(227, 227, 227, 1) 0%,
    rgba(247, 247, 247, 0) 18%
  );
}
.book .book__page-front {
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotateY(10deg);
}

.book .book__page {
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotateY(-120deg);
}
