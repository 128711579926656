.user-profile-layout {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.user-profile-card-one {
  width: 18%;
  height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 50px;
}

.user-profile-card-two {
  width: 65%;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 5px;
}

.user-avatar-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.user-avatar-circle img {
  width: 95%;
  height: 95%;
  border-radius: 50%;
}

.username-profile {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.username-profile span {
  color: #6e7191;
}

.username-profile span:first-child {
  font-size: 15px;
  text-align: center;
}

.username-profile span:last-child {
  font-size: 13px;
  text-align: center;
}

.profile-pic-chaageImage {
  width: 130px;
  height: 40px;
  margin-top: 10px;
}

.profile-pic-chaageImage input {
  width: 130px;
  height: 40px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.profile-pic-chaageImage button {
  width: 130px;
  height: 40px;
  background-color: #ee7f0d;
  font-size: 12px;
  border-radius: 4px;
  color: white;
}

.user-profile-card-two {
  display: flex;
}

.user-profile-card-two-inner-two {
  width: 1px;
  height: 510px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  margin-top: 50px;
}

.userProfile-input-header {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: #002233;
}

.profileinput-box p {
  margin: 0;
  padding: 0;
  position: relative;
  top: 24px;
  color: #a0a3bd;
  font-size: 13px;
  padding-left: 7px;
  z-index: 10;
  z-index: 1;
}

.move-label-up-profile {
  position: relative !important;
  top: 2px !important;
  color: orange !important;
  transition: all 200ms ease-in;
  z-index: 3 !important;
  margin-left: -7px !important;
}

.profileinput-box input {
  opacity: 1;
  background: #eff0f759;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 45px;
  position: relative;
  top: -7px;
  z-index: 2;
  padding-left: 4px;
  font-size: 13px;
  color: #131314d3;
  padding-left: 10px;
}

.profileinput-box .PhoneInput {
  opacity: 1;
  background: #eff0f759 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-left: 10px !important;
  width: 350px !important;
  height: 45px !important;
  position: relative;
  top: -7px;
  z-index: 2;
  padding-left: 4px;
  font-size: 13px;
  color: #131314d3;
  padding-left: 10px;
}

.profileinput-box .PhoneInput .PhoneInputInput {
  border: none !important;
  position: relative;
  top: 1px;
}

.profileinput-box textarea {
  opacity: 1;
  background: #eff0f759;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 115px;
  position: relative;
  top: -7px;
  padding-left: 4px;
  z-index: 2;
  outline: none;
  padding-left: 10px;
  padding-top: 12px;
  font-size: 13px;
  color: #131314d3;
}

.user-profile-card-two-inner-one {
  padding: 10px 20px 20px 15px;
}

.appErrorProfil {
  margin-top: -8px !important;
  width: 340px;
  margin-bottom: 10px;
  font-size: 9px;
}

.user-profile-card-two-inner-three {
  margin-left: 25px;
  margin-top: 10px;
}
.social-handle-profile {
  margin-top: 20px;
}
.socila-m-profile-inner img {
  width: 5%;
}
.socila-m-profile-inner input {
  border-color: red;
  width: 75%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25) !important;
  margin-left: 16px;
  position: relative;
  top: -7px;
  font-size: 11px;
  margin-bottom: 19px;
  padding-bottom: 7px;
}

.profile-newsletter-box-inner {
  display: flex;
  width: 83% !important;
  font-size: 15px !important;
  margin-bottom: 10px;
  background: #eff0f6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;

  padding: 5px;
}

.profile-newsletter-box-inner span {
  width: 90%;
  font-size: 13px !important;
  color: #a0a3bd;
}
.inner-review {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: white;
  padding: 20px 14px;
  border-radius: 5px;
}
.review-img img {
  width: 100px;
  height: 100px;
}

.review-text {
  margin-left: 0;
  width: calc(100% - 112px);
}

.review-text p {
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  overflow: hidden;
  text-overflow: ellipsis;
}
.review-actions {
  margin: 0;
  padding: 0;
}

.review-actions img {
  margin-right: 20px;
  margin-top: 0;
}

.reviews-wrap {
  border-radius: 5px;
  padding: 15px;
  width: 90%;
  display: flex;
  justify-self: center;
  align-self: center;
  margin: auto;
  flex-direction: column;
}

.subscription-wrap {
  display: flex;
  justify-content: flex-start;
}

.col-one-subscription {
  background-image: url(../../assets/pub.png);
  width: 25%;
  height: 490px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 60px;
  flex-direction: column;
}

.col-one-subscription p {
  color: white;
  text-align: center;
}

.col-one-subscription button {
  color: white;
  background-color: #ee7f0d;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  margin-top: -7px;
}
.col-two-subscription {
  margin-left: 20px;
  width: 830px;
}

.sub-img-wrap {
  width: 126px;
  height: 120px;
  border-right: 1.4px solid rgba(0, 0, 0, 0.1);
  padding-right: 7px;

  margin-bottom: 20px;
}

.sub-button-wrap {
  border-left: 1.4px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-left: 15px;
  justify-content: center;
  padding-left: 10px;
  gap: 10px;
}

.sub-button-wrap button {
  color: white;
  background-color: #ee7f0d;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 13px;
}

.sub-button-wrap button:last-child {
  background: #f7f7fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: #002333;
}

.sub-img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.subs-wrap-inner {
  display: flex;
  justify-content: center;
}

.sub-about-wrap {
  margin-left: 15px;
}

.hr-sub {
  width: 100%;
  height: 1px;
  border: 1px dashed rgba(0, 0, 0, 0.25);

  margin-top: 16px;
  margin-bottom: 16px;
}
.sub-tit {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  color: #000000;
}

.sub-type {
  font-size: 12px;
  letter-spacing: 0.75px;
  text-transform: capitalize;

  color: #6e6e6e;
}

.sub-next {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.sub-warn {
  font-size: 12px;
  letter-spacing: 0.75px;
  color: #000000;
  font-style: italic;
}

.subs-wrap {
  background-color: white;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 19px;
  padding-bottom: 0px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.subscription-header {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}

.following-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
}

.following-cards > div {
  margin-left: 10px;
}
.following-img-wrap img {
  width: 100%;
  height: 155px;
}

.following-profile {
  margin-left: 57px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.reviewjk-profile {
  margin-left: 0px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.continue-reading {
  margin-left: 37px !important;
  color: #ee7f0d;
  font-style: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.following-uname {
  color: #6e7191;
  font-weight: 600;
  font-style: 20px;
}

.fol-fol {
  font-size: 13px;
  color: #6e7191;
}

.following-wrap {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
  width: 100vw;
  grid-gap: 10px;
  padding-right: 20px;
}

.subAccountSub button {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
}

.editProfilebtn {
  padding: 10px;
  background-color: #ee7f0d;
  border-radius: 5px;
  margin-top: 20px;
  color: white;
  font-size: 12px;
}

.changeUserPassword {
  width: 100%;
  padding: 20px;
  padding-top: 40px;
}

.changePassinputWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.changePassinputWrap label {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: black;
  padding-bottom: 5px;
}

.changePassinputWrap input {
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  font-size: 13px;
  padding-left: 10px;
}

.updateUserPaswordTitle {
  text-align: center;
}

.updateUserPaswordTitle h1 {
  font-size: 18px;
  margin-bottom: 40px;
}

.subComment-wrap {
  background-color: rgba(128, 128, 128, 0.063);
  padding: 10px;
}

.subAccountInputWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}
.subAccForm {
  padding-bottom: 40px;
}

.subAccountInputtaglabel {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #000000;
  font-size: 14px;
}

.subAccountInputWraphgf .subAccountInputtag {
  border: 1px solid rgba(128, 128, 128, 0.062);
  width: 100%;
  border-radius: 4px;
  color: black;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: rgba(128, 128, 128, 0.062);
  font-size: 12px;
  margin-top: 5px;
  padding-left: 4px;
}

.subAccountInputWraphgf .subAccountInputtag option {
  font-size: 12px !important;
}

.react-select .css-1s2u09g-control {
  border: none !important;
  background-color: rgba(128, 128, 128, 0.062);
  font-size: 12px !important;
}

.react-select .css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
  background-color: rgba(128, 128, 128, 0.062);
}

.react-select .css-1pahdxg-control {
  border: none !important;
}

.react-select .css-2613qy-menu {
  padding-bottom: 20px !important;
}

.handleSubMe {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
}

.handleSubMe button {
  background-color: #ee7f0d;
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

@media (max-width: 1245px) {
  .user-profile-card-two {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .profileinput-box input,
  .profileinput-box textarea,
  .profile-newsletter-box-inner,
  .socila-m-profile-inner input {
    width: 90% !important;
  }
}

@media (max-width: 1083px) {
  .user-profile-layout {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .user-profile-card-two {
    margin-top: 20px;
  }
  .user-profile-card-one {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
}

@media (max-width: 803px) {
  .user-profile-card-two-inner-two {
    display: none !important;
  }
  .user-profile-card-two-inner-one {
    padding-right: 0;
  }
  .user-profile-card-two-inner-three {
    margin-left: 0;
  }
}
@media (max-width: 735px) {
  .user-profile-card-two {
    flex-direction: column;
    width: 100%;
  }
  .user-profile-card-two-inner-one {
    padding: 20px;
  }
  .user-profile-card-two-inner-three {
    margin: 20px;
  }
  .profileinput-box input,
  .profileinput-box textarea,
  .profile-newsletter-box-inner {
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .following-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .following-cards {
    width: 95%;
  }
  .section-wrap {
    padding-left: 10px;
  }
  .following-img-wrap img {
    height: 363px;
  }
  .following-profile {
    text-align: center;
  }
}
@media (max-width: 707px) {
  .reviews-wrap {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .inner-review {
    width: 100%;
    /* padding: 0; */
  }

  .section-wrap {
    padding: 10px;
  }
}

@media (max-width: 420px) {
  .inner-review {
    width: 100%;
    flex-direction: column;
  }
  .review-img img {
    width: 100%;
    height: 300px;
  }
  .review-text {
    width: 100%;
    margin-top: 10px;
  }
  .reviewjk-profile {
    text-align: center;
  }
}

@media (max-width: 1094px) {
  .col-one-subscription {
    display: none !important;
  }
  .sub-button-wrap button {
    white-space: nowrap;
    font-size: 10px;
  }
}

@media (max-width: 740px) {
  .subs-wrap-inner {
    padding-bottom: 20px;
  }
}

@media (max-width: 590px) {
  .subs-wrap-inner {
    flex-direction: column;
  }
  .sub-img-wrap {
    width: 100%;
    height: 400px;
  }
  .col-two-subscription {
    margin-left: 0;
  }
  .sub-about-wrap {
    margin-left: 15px;
    margin-bottom: 17px;
  }
}
