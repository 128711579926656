.changeofNameBanner {
  width: 100vw;
  background-image: url(../../assets/changOfNameBanner.png);
  background-size: cover;
  height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px 10px 80px;
}
.chNameTextMain h1 {
  color: white;
  font-size: 40px;
  font-weight: 600;
  color: #eaf6fc;
}

.chNameTextMain p {
  font-size: 18px;
  color: #eaf6fc;
  margin-top: 20px;
}

.drpSelOp select {
  background: #eff0f7;
  border-radius: 7px;
  width: 350px;
  height: 50px;
  padding: 10px;
}

.newspaperListChangeOfname {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
  /* min-width: 5600px; */
  width: 80vw;
  margin-top: 50px;
  grid-gap: 14px;
}

@media (max-width: 484px) {
  .newspaperListChangeOfname {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.newspaperListChangeOfname_child {
  width: 200px;
  height: 247px;
  background: #ffffff;
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 12px;
  overflow: hidden;
  padding: 20px;
  transition: all 400ms ease;
}

.newspaperListChangeOfname_child:hover {
  transform: translateY(-20px);
  background-color: rgba(128, 128, 128, 0.137);
  border: 1px solid rgba(238, 126, 13, 0.644);
  border-radius: 5px;
}

.newspaperListChangeOfname_child img {
  /* width: 90%; */
  height: 100px;
  /* margin-top: 30px; */
}

.changeOfNameTabWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.changeOfNameTabWrapIn {
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeOfNameTabWrapIn ul {
  list-style-type: none;
  display: flex;
  margin-top: 50px;
  gap: 40px;
}

.changeOfNameTabWrapIn ul li {
  /* margin-right: 47px; */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #d9dbe9;
}
/* 
.progress_tab_wrapper {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}

.progress_tab_wrapper .progress_tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.progress_tab_wrapper .progress_tab .progress_tab_number {
  width: 57px;
  height: 57px;
  border-radius: 100%;
  background: #ee7f0d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  font-weight: 500;
}

.progress_tab_wrapper .progress_tab .progress_tab_label {
  font-size: 14px;
  font-weight: 400;
  color: #ee7f0d;
  margin: 0;
} */

.tab_label {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #002233;
}

.trsjgfrlosa span:first-child {
  margin-right: 20px;
}

.ftrsjgfrlosaqpbt {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d9dbe9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 12px;
  color: white;
}

.inputChangeNameBox {
  width: 500px;
  margin-top: 20px;
}

.inputnwCh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.inputnwCh .mwhgeqinput {
  width: 48%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  font-size: 13px;
  color: #7c7c7c;
}

.inputnwCh .mwhgeqinputDiv {
  width: 48%;
  display: flex;
  flex-direction: column;
  /* height: 45px; */
  font-size: 14px;
}

.inputnwCh .mwhgeqinputDiv input {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  font-size: 13px;
  color: #7c7c7c;
}

.inputnwCh .mwhgeqinputDiv label {
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.inputnwCh textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  color: #7c7c7c;
}

.actionBtnCName {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBtnCName button {
  margin: 40px;
  width: 130px;
  height: 45px;
  border-radius: 4px;
  color: white;
  background-color: transparent;
}
.actionBtnCName button:first-child {
  border: 1px solid #ee7f0d;
  color: #ee7f0d;
}
.actionBtnCName button:last-child {
  background-color: #ee7f0d;
}
.inputnwChtab3 {
  display: flex;
}
.inputnwChtab3 .inputnwChtab3FakeFileI {
  width: 370px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 7px 0 0px 7px;
  cursor: pointer;
}

.inputnwChtab3label {
  position: absolute;
  left: 12px;
  font-size: 12px;
  top: 12px;
  cursor: pointer;
}
.inputnwChtab3 .mwhgeqinputTab3 {
  width: 370px;
  height: 40px;
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
  cursor: pointer;
}

.inputnwChtab3Button {
  border-radius: 0px 7px 7px 0px;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  background: #ee7f0d;
  color: white;
  font-size: 11px;
}

.inputChangeNameBoxtab3 textarea {
  width: 95%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 120px;
  font-size: 13px;
  padding: 12px;
}

.inner-modal-wrap-trvChN {
  width: 885px;
  background-color: #fff;
}

.previewChangeOfname1Wrap {
  padding: 30px 80px;
}

.changeOfNameNewNPreview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 60px;
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.confirm {
  font-size: 16px;
  color: #ff0000;
  font-weight: 400;
}

/* 
.changeOfNameNewNPreviewOne h2,
.changeOfNameNewNPreviewtwo h2 {
  font-size: 13px !important;
  font-weight: 600;
  margin: 0;
} */

.changeOfNameNewNPreviewOne p,
.changeOfNameNewNPreviewtwo p {
  font-size: 13px;
  color: #4e4b66;
  margin: 0;
}

.changeOfNameNewNPreviewOne .label,
.changeOfNameNewNPreviewtwo .label {
  font-size: "16px";
  font-weight: 700;
  color: "#000000";
}

.cNLine {
  width: 100%;
  /* height: 1px; */
  border: 0.5px dashed #ee7e0d77;
}
.dcCns {
  display: flex;
}
.dcCns div {
  width: 120px;
  height: 150px;
  background-color: #4e4b66;
  margin-right: 13px;
  box-shadow: 0px 6.71186px 16.7797px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  border-radius: 4px;
}

.cnameAboutPub {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.cnameAboutPub img {
  margin-right: 50px;
}

.spLogosCN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 70px;
}
.spLogosCN img {
  width: 160px;
}

.cnNoticeg {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cnNoticeg h1 {
  font-size: 17px;
  margin-bottom: 12px;
}

.cnNoticeg p {
  font-size: 14px;
  text-align: center;
  color: #6e7191;
}

.cnNoticeg p span {
  color: #ee7e0dc5;
}
@media (max-width: 1110px) {
  .changeofNameBanner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    height: 400px;
  }
  .drpSelOp {
    margin-top: 25px;
  }
  .chNameTextMain p {
    font-size: 15px;
  }

  .chNameTextMain h1 {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .drpSelOp {
    width: 100%;
  }
  .drpSelOp select {
    width: 90%;
    margin-top: 6px;
  }
}

@media (max-width: 611px) {
  .inner-modal-wrap-trvChN {
    width: 90vw;
  }

  .changeOfNameNewNPreview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 60px;
  }

  .changeOfNameTabWrapIn {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .previewChangeOfname1Wrap {
    padding: 30px;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .confirm {
    font-size: 12px;
    color: #ff0000;
    font-weight: 400;
  }

  .cnameAboutPub {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding-top: 10px;
  }
}

@media (max-width: 532px) {
  .changeOfNameTabWrapIn ul {
    justify-content: center;
  }
  .trsjgfrlosa {
    display: none;
  }
  .cangeONameHeasderRes {
    width: 90%;
    text-align: center;
  }

  .inputnwCh {
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .inputnwCh .mwhgeqinput {
    width: 90vw;
    margin-bottom: 16px;
  }
  .inputChangeNameBox {
    margin-top: 30px;
  }

  .inputnwChtab3 .inputnwChtab3FakeFileI {
    width: 55%;
    overflow: hidden;
  }

  .inputnwChtab3 .mwhgeqinputTab3 {
    width: 65%;
    overflow: hidden;
  }

  .inputnwChtab3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
  }

  .inputnwChtab3label {
    left: inherit;
    white-space: nowrap;
  }

  .actionBtnCName button:first-child {
    margin-right: 0;
  }
}

.pubDays3h .pubDays3hCh1::after {
  content: "|";
  margin-right: 5px;
  margin-left: 5px;
}

.pubDays3h .pubDays3hCh1:last-child::after {
  content: "";
}

.Applicationcontainer {
  width: 90%;
  padding: 50px;
  background-color: white;
}

.Applicationcontainer .ApplicationcontainerMain {
  border: 1px solid rgba(0, 0, 0, 0.226) !important;
  border-radius: 10px;
}

.Applicationcontainer .MuiTypography-h6 {
  font-size: 12px !important;
}

.MuiButtonBase-root div {
  font-size: 12px !important;
}

.tabsAppli ul {
  display: flex;
  list-style-type: none !important;
  position: relative;
  bottom: -30px;
  z-index: 1233;
  margin-left: 20px;
}

.tabsAppli ul li {
  margin-right: 40px;
  color: #8692a6;
  font-size: 14px;
  display: block !important;
  text-align: center;
  cursor: pointer;
}

.tabsAppli ul .activedjsehapp::after {
  content: "";
  display: block !important;
  width: 100%;
  height: 2px;
  background: #ee7f0d;
}

.upSelsf {
  text-transform: capitalize !important;
}
