.login-wrap-home {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #ee7f0c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 70px;
}

.login-wrap-home .login-header-text {
  font-weight: 500;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
  line-height: 30px;
}

.login-wrap-home-input {
  min-width: 480px;
  max-width: 90vw;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 50px;
}
.createAccountLogo {
  margin-bottom: 30px;
}
.login-form-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-col-one-home button {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 270px;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.login-col-one-home button img {
  margin-right: 10px;
}

.login-col-three-home input {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.submit-login-details-home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.submit-login-details-home button {
  background: #ee7f0d;
  border-radius: 5px;
  width: 90%;
  height: 50px;
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.forgot-password-wrap-home {
  display: flex;
  justify-content: center;
}

.forgot-password-wrap-home a {
  margin-right: 17px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.75px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #666666;
}

.login-col-three-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.login-col-three-home input {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 60px;
  color: #002333;
  font-size: 14px;
  line-height: 28px;
}

.create-account-wrap {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  background-color: white;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  padding-left: 90px;
}

.create-account-col-one-wrap {
  width: 27%;
}

.create-account-col-two-wrap {
  width: 50%;
  margin-left: 125px;
}

.tns-create-account-logo {
  margin-bottom: 30px;
}

.offer-tns-home-create-acc {
  margin-bottom: 30px;
}

.offer-tns-home-create-acc h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #002333;
  margin: 0;
}

.offer-tns-home-create-acc h1 img {
  margin-right: 5px;
}

.offer-tns-home-create-acc p {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #4e4b66;
  margin-left: 25px;
  padding-top: 10px;
}
.create-account-col-two-wrap h1 {
  font-weight: 600;
  font-size: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  color: #002333;
}

.account-type-home {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 23px;
}
.account-type-home span {
  margin-right: 20px;
  color: #6e7191;
  font-size: 13px;
}

.account-type-home span input {
  margin-left: 6px;
  margin-top: 5px;
}

.input-wrap-create-acc {
  /* margin-bottom: 20px; */
}

.input-wrap-create-acc label {
  font-size: 14px;
  letter-spacing: 0.75px;
  color: #6e7191;
  margin-bottom: 4px;
}

.input-wrap-create-acc .input-create-acc input::-webkit-input-placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input::-moz-placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input:-ms-input-placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input::-ms-input-placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input::placeholder {
  font-size: 10px;
}

.input-wrap-create-acc .input-create-acc input {
  font-size: 13px;
  width: 350px;
  height: 45px;

  background: #eff0f7;
  border-radius: 8px;
}
.input-wrap-create-acc .PhoneInput {
  font-size: 13px;
  width: 90% !important;
  height: 45px;
  padding-left: 10px !important;
  background: #eff0f7 !important;
  border-radius: 8px;
}
.PhoneInputInput {
  background-color: transparent !important;
}
.create-acc-newlette h1 {
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  letter-spacing: 0.75px;
  color: #6e7191;
  margin-bottom: 11px;
}

.create-acc-newlette p {
  font-weight: 300;
  font-size: 11px;
  color: #6e7191;
  margin: 0;
  /* line-height: 0px; */
  padding-top: 0px;
}

.create-acc-newlette p input {
  margin-right: 10px;
  margin-top: -5px;
}

.submit-login-createAcc {
  justify-content: flex-start;
  width: 100%;
}

.submit-login-createAcc button {
  justify-content: flex-start;
  width: 350px;
}
.loginAppLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  margin-bottom: 31px;
  margin-top: -32px;
}
.error-container {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -20px;
}
/* RESPONSIVE DESIGN */

@media (max-width: 1005px) {
  .login-wrap-home-input {
    width: 90vw;
  }
  .login-col-three-home input {
    width: 90%;
  }
  .submit-login-details-home button {
    width: 90%;
  }

  .error-container {
    width: 85%;
  }
}

@media (max-width: 682px) {
  .login-form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 705px) {
  .hideOnDesktopCreateAccountLogo {
    display: none !important;
  }
}
@media (max-width: 705px) {
  .create-account-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-left: 10px;
  }
  .create-account-wrap {
    margin-top: 30px;
  }
  .create-account-col-two-wrap .firstHeaderCr {
    text-align: center;
    justify-content: center;
    margin-top: 30px;
    color: #ee7f0d;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .hdCreM {
    display: none !important;
  }

  .pPMr {
    top: 0 !important;
    margin-left: 8px;
  }
  .accountTypehfo {
    display: flex;
    justify-content: space-between;

    margin-bottom: 25px;
    margin-top: 32px;
  }

  .account-type-home span {
    margin-right: 0;
  }
  .input-wrap-create-acc .input-create-acc input {
    width: 100%;
  }

  .togglePasscreAcc {
    width: 100% !important;
    position: relative;
  }

  .css-2b097c-container {
    width: 90% !important;
    height: 45px !important;
    background: #eff0f7 !important;
    border-radius: 8px;
  }
  .submit-login-details-home button {
    width: 100%;
  }
  .forgot-password-wrap-home {
    display: flex;
    justify-content: center !important;
    align-items: center;
    margin: auto;
    margin-bottom: 35px;
  }
  .create-account-col-one-wrap {
    display: none;
  }

  .create-account-col-two-wrap {
    margin-left: 0;
    width: 90%;
  }
}

.toggleViewPassword {
  position: relative;
  width: 90%;
}

.toggleViewPassword i {
  position: absolute;
  top: -57px;
  right: -20px;
  font-size: 18px;
  cursor: pointer;
  color: #ee7f0d;
}

.togglePasscreAcc {
  width: 350px;
  position: relative;
}

.togglePasscreAcc i {
  position: absolute;
  right: 0;
  top: -29px;
  font-size: 18px;
  cursor: pointer;
  color: #ee7f0d;
}

.inPutHomeWrapP {
  width: 90%;
}

@media (max-width: 600px) {
  .inPutHomeWrapP {
    width: 100%;
  }
  .login-wrap-home-input {
    min-width: 90vw;
  }

  .login-col-three-home input {
    width: 100%;
  }

  .toggleViewPassword i {
    position: absolute;
    top: -57px;
    right: 0 !important;
    font-size: 18px;
    cursor: pointer;
    color: #ee7f0d;
  }
}

.forgot-password-wrap-home a {
  color: white !important;
}

@media (max-width: 400px) {
  .forgot-password-wrap-home {
    flex-direction: column;
  }
  .forgot-password-wrap-home a {
    margin-bottom: 7px;
  }
}

/* 
LOGIN PAGE DESIGN V2 */

.login-wrap-v2 {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: white;
  width: 100vw;
}

.login-wrap-v2-col1 {
  height: 100%;
  width: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.507), rgba(0, 0, 0, 0.3)),
    url(../../assets/loginBanner2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px 20px 40px;
}

.heroTextSection {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  height: 75%;
}

.heroTextSection h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 51px;
  color: #ffffff;
}

.heroTextSection p {
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #fffffffa;
  margin-top: 20px;
}

.heroTextSection button {
  width: 170px;
  height: 50px;
  background-color: rgb(238, 127, 13);
  color: white;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 400ms ease;
  font-size: 15px;
}

.heroTextSection button:hover {
  transform: translateY(-10px);
}

.login-wrap-v2-col2 {
  height: 100%;
  width: 50%;
  padding: 40px 20px 20px 40px;
  overflow: hidden;
  overflow-y: scroll;
}

.login-wrap-v2-col2 ::-webkit-scrollbar {
  opacity: 0 !important;
}

.signUpOptonLogin {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.signUpOptonLogin p {
  font-size: 13.5px;
  cursor: pointer;
}

.signUpOptonLogin p span a {
  font-size: 13.5px;
  color: rgb(238, 127, 13) !important;
  font-weight: 600;
}

.login-wrapv2-main {
  border: 1px solid #f7f7fc;
  width: 78%;
  padding: 40px 40px 50px 40px;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 50px;
}

.login-wrapv2-main .login-txt-header {
  font-size: 27px;
  font-weight: 600;
  color: black;
}

.inputWrap-v2 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.inputWrap-v2 input {
  background: #eff0f7;
  border: 1px solid rgba(189, 189, 189, 0.44);
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  width: 90%;
  font-size: 12px;
  border: none;
  color: #a0a3bd;
}

.inputWrap-v2 label {
  font-size: 14px;
  color: #6e7191;
}

.login-wrapv2-main {
  margin-top: 30px;
}

.forgotPasswordOptionv2 {
  margin-top: 13px;
}
.forgotPasswordOptionv2 p a {
  color: #ee7f0d !important;
  font-size: 13px;
  cursor: pointer;
}

.loginBtnV2 {
  width: 100%;
  margin-top: 23px;
}

.loginBtnV2 button {
  background-color: #ee7f0d;
  height: 45px;
  width: 90%;
  border-radius: 4px;
  color: #ffff;
  transition: all 300ms ease;
}

.loginBtnV2 button:hover {
  -webkit-filter: drop-shadow(6px 5px 6px rgba(128, 128, 128, 0.795));
  filter: drop-shadow(6px 5px 6px rgba(128, 128, 128, 0.651));
}

.mobileViewLogoV2 {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.mobileViewLogoV2 img {
  width: 200px;
}

@media (min-width: 1018px) {
  .mobileViewLogoV2 {
    display: none;
  }
}

@media (max-width: 1018px) {
  .login-wrapv2-main {
    width: 95%;
    padding: 20px;
  }

  .signUpOptonLogin {
    margin-top: 20px;
    padding-right: 20px;
  }

  .login-wrap-v2-col1 {
    display: none;
  }

  .login-wrap-v2-col2 {
    width: 100%;
  }
}

.haveanAccountV2Op {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.haveanAccountV2Op p {
  color: black;
  font-size: 14px;
}
.haveanAccountV2Op p a {
  color: #ee7f0d !important;
  font-weight: 600;
}

@media (max-width: 654px) {
  .login-wrapv2-main {
    width: 100%;
    padding: 20px;
  }

  .login-wrap-v2-col2 {
    padding: 0;
  }

  .login-wrapv2-main {
    padding: 20px 15px 10px 15px;
  }

  .inputWrap-v2 input {
    width: 100%;
  }

  .input-wrap-create-acc .PhoneInput {
    width: 100% !important;
  }

  .css-2b097c-container {
    width: 100% !important;
  }

  .loginBtnV2 button {
    width: 100% !important;
  }
}
