#archivePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin-top: 40px;
}
.archivePageContainer {
  width: 470px;
  padding-bottom: 70px;
}

.achiveHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.achiveHeader h1 {
  font-size: 15px;
}

.achiveHeader p {
  font-size: 13px;
}

.achiveHeader * {
  margin: 0;
  padding: 0;
}

.achiveHeader h1 {
  margin-bottom: 8px;
}
.achiveHeader p {
  color: #6e7191;
}
.achiveHeader p span {
  color: #ee7f0d;
  font-weight: 600;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
}
.inputWrapAchive {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inputWrapAchive .inputWrapAchiveMain {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 7px;
  font-size: 13px;
  padding: 10px;
  color: #4e4b66;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.submitAchive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 83%;
  margin-top: 20px;
}
.submitAchive span {
  color: #000000;
  font-size: 15px;
}

.submitAchive span span:first-child {
  color: #ee7f0d;
  font-weight: 600;
  margin-left: 5px;
}

.submitAchive button {
  background-color: #ee7f0d;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
}

.selctAchiveP {
  height: 100% !important;
  padding: 50px;
  position: relative;
  z-index: 20000;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100% !important;
  height: 100% !important;
}

.addMoreDates {
  display: flex;
  width: 170px;
  height: 45px;
  margin-top: 0px;
  background: rgba(238, 127, 13, 0.14);
  border-radius: 24px;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  color: #ee7f0d;
  margin-left: 30px;
  cursor: pointer;
}

.addMoreDates img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.rminputWrapPad {
  margin-bottom: 15px;
}

.mtdatexand {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: red;
  font-size: 12px;
  cursor: pointer;
  font-style: italic;
  /* margin-bottom: 20px; */
}
