.textViewContainer-grid {
  /* display: grid;
  grid-template-areas:
    "col1 col2 col2"
    "col1 col2 col2"
    "col1 col3 col4"; */
  /* height: 900px; */
  /* grid-template-rows: 1fr 1fr 1fr; */
  /* grid-template-columns: 350px 1fr 1fr; */
  /* grid-gap: 15px; */
  max-width: 1040px;
  margin: auto;
  /* background-color: pink; */
}
.textViewWithImage {
  width: 80%;
  position: relative;
  left: 230px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #e5e5e5;
  /* margin-top: 30px; */
  margin-left: 2.5%;
  box-shadow: 0px 0px 8px rgba(211, 211, 211, 0.371);
  border-radius: 5px;
  position: relative;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  overflow-y: scroll;
}
@media (max-width: 1089px) {
  .textViewWithImage {
    width: 97%;
    left: 0;
  }
}
.tviewgrid1 {
  grid-area: col1;
  height: 800px;
  /* background-color: orange; */
}

.tviewgrid2Img {
  grid-area: col2;
}

.tviewgrid2Img img {
  width: 100%;
  padding-right: 0;
  border-radius: 4px;
}

.tviewgrid3 {
  grid-area: col3;
  height: 350px;
  width: 350px;
  padding: 5px;
}

.tviewgrid4 {
  grid-area: col4;
  height: 350px;
  width: 350px;
}

.postTitleST {
  max-width: 1040px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 22px;
}

.postTitleST h1 {
  font-family: var(--textViewPdfFont) !important;
}

.post-des {
  margin-top: 0;
  font-weight: 400;
  color: #000000;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: -15px;
}

.post-autor {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
}

.postTags {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 15px;
}

.sk-editor-render-style * {
  padding: 0;
  margin: 0;
}

.sk-editor-render-style p {
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  line-height: 35px;
  margin-bottom: 4px !important;
}

.sk-editor-render-style iframe {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 450px;
}

.sk-editor-render-style img {
  width: 45%;
  /* height: 700px; */
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* padding-left: 20px; */
  float: right;
  transition: all 400ms ease;
}

.sk-editor-render-style img:hover {
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}

.sk-editor-render-style h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-size: 19px !important;
  line-height: 18px !important;
  color: #000000;
  line-height: 28px !important;
  margin-bottom: 5px;
  margin-top: 25px !important;
  font-family: var(--textViewPdfFont) !important;
}

.sk-editor-render-style iframe {
  width: 100% !important;
  border-radius: 5px;
}

.sk-editor-render-style p:empty {
  display: none !important;
}

.sk-editor-render-style a {
  color: #ee7f0d !important;
  /* text-decoration: underline !important; */
}
.sk-editor-render-style {
  padding-bottom: 70px;
}
.textWithImage-remainder {
  display: grid;
  grid-template-columns: 330px 330px 330px;
  grid-gap: 10px;
  max-width: 1040px;
  margin: auto;
  margin-top: 20px;
}

.allUsedTags {
  margin-right: 5px;
}

/* .app-container {
  background-color: red;
} */

.textViewCoverImage {
  width: 100%;
  /* height: 600px; */
  overflow: hidden;
  /* border: 2px solid #ee7e0d3a; */
  display: flex;
  height: 400px;
  border-radius: 5px;
  /* padding: 20px; */
  overflow: hidden;
}

.textViewCoverImage img {
  /* width: 100%; */
  border-radius: 5px;
  /* height: 100%; */
  transition: all 500ms ease;
  cursor: pointer;
  float: right;
}

.textViewCoverImage img:hover {
  /* transform: scale(1.2); */
}

.userGiftingMessage {
  height: 45px;
  width: 100px;
  position: fixed;
  right: -26px;
  z-index: 999;
  bottom: 103px;
}

.userGiftingMessage img {
  width: 43px;
  cursor: pointer;
  margin-left: 10.5px;
  margin-top: 11px;
}

.userGiftingMessage-modal {
  height: 340px;
  width: 300px;
  background-color: white;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 70px;
  padding: 20px;
  border-radius: 4px;

  -webkit-filter: drop-shadow(4px 5px 5px rgba(128, 128, 128, 0.678));

  filter: drop-shadow(4px 5px 5px rgba(128, 128, 128, 0.678));
  overflow-y: scroll;
}

.userGiftingMessage-modal::-webkit-scrollbar {
  opacity: 0;
}
.gift-user-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(238, 127, 13, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.gift-user-logo img {
  width: 40px;
}

.closeGiftModalCss {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.closeGiftModalCss img {
  width: 40px;
  background: rgba(0, 0, 0, 0.08);
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.gifting-msg-body {
  padding-top: 22px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.gifting-msg-body img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
}
